import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HiOutlineAdjustments, HiOutlineEye, HiOutlineEyeOff, HiOutlineHome, HiOutlineLogout, HiOutlineMenu, HiOutlineUser, HiOutlineUserCircle, HiOutlineX } from "react-icons/hi";
import UserAuth from '../Context/UserAuth/UserContext';
import Modal from '../UI/Modal'
import Input from '../UI/Input'
import Button from '../UI/Button'
import { userPasswordChange } from '../libs/apis';
import toast from 'react-hot-toast';

export default function Menu() {
    const [menu, setMenu] = useState(false)
    const [loading, setLoading] = useState(false)
    const [settings, setSettings] = useState(false)
    const [inpuType, setInpuType] = useState(false)
    const [password, setPassword] = useState('')
  
    const settingF = () => {
        setSettings(false)
        setPassword('')
    }

    const inputTypeF = () => {
        setInpuType(!inpuType)
    }

    const location = useLocation();

    let style = 'text-center block text-md p-3 w-full rounded-lg hover:bg-purple-800 transition-all duration-300 ';
    let backDrop = 'fixed backdrop-blur-sm w-full h-screen top-0 left-0 transition-all lg:hidden duration-300 opacity-0 ';
    let menuStyle = 'lg:py-6 lg:block fixed lg:static bg-purple-900 top-0 p-5 py-16 lg:p-0 h-screen lg:h-auto w-full sm:w-1/2 lg:w-full transition-all duration-300 ';

    const navigate = useNavigate();

    const {user, setUser, setToken, setLoginStatus} = useContext(UserAuth)

    // User logout
    const logout = () => {
        setUser({ email: '', id: '', role: ''})
        setToken({})
        setLoginStatus(false)
        localStorage.removeItem('userToken')
        setTimeout(()=>{
            navigate('/login')
        },3000)
    }

    // Password Generator
    const generatePassword = (inp) => {
        var length = 12,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        if(inp === 1 ){
        setPassword(retVal)
        } else {
        setPassword(retVal)
        }
    }

    // update passowrd
    const updatePassword = async () => {
        setLoading(true)
        const data = {password, uniqueId: user.id}
        if(password) {
            let res = await userPasswordChange(data)
            if(res?.status){
                toast.dismiss();
                toast.success(res?.message,{
                duration:5000,
                position: 'bottom-center',
                })
                setLoading(false)
                setPassword('')
                setSettings(false)
            } else {
                toast.dismiss()
                toast.error(res?.message,{
                    duration:5000,
                    position: 'bottom-center',
                })
                setLoading(false)
            }
        } else {
            toast.dismiss()
            toast.error('Enter your passowrd.',{
                duration:5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
    }

    return (
        <>
            <button className='text-3xl block lg:hidden' onClick={_=> setMenu(true)}>
                <HiOutlineMenu />
            </button>
            <div className={menu ? backDrop + 'block opacity-100': backDrop + 'hidden'} onClick={_=> setMenu(false)}></div>
            <div className={menu ? menuStyle + 'left-0': menuStyle + '-left-full'}>
                <HiOutlineX className="lg:hidden absolute top-4 right-4 text-white text-3xl cursor-pointer" onClick={_=> setMenu(false)}/>
                {/* logout */}
                <div className='flex justify-between bg-purple-700 px-3 py-2 rounded-lg'>
                    <div className='flex items-center gap-2'>
                        <HiOutlineUserCircle className='text-xl'/>
                        <h3>{user.name} |
                            {
                                (user.role === 22 && ' Introducer') || (user.role === 33 && ' Solicitor') || (user.role === 39 && ' Manager')
                            }
                        </h3>
                    </div>
                    <button className='text-md flex items-center gap-1' onClick={logout}>
                        <HiOutlineLogout className='text-xl'/> Logout
                    </button>
                </div>
                <ul className='mt-5'>
                    <li className='mb-3'>
                        <Link to='/' className={location.pathname === '/' ? style + ' bg-purple-800' : style}>
                            <HiOutlineHome className='text-2xl inline-block float-left' />
                            Dashboard
                        </Link>
                    </li>
                    <li className='mb-3'>
                        <Link to='/matters' className={location.pathname === '/matters' ? style + ' bg-purple-800' : style}>
                            <HiOutlineUser className='text-2xl inline-block float-left' />
                            Matters
                        </Link>
                    </li>
                    <li className='mb-3'>
                        <button className={style} onClick={_=> setSettings(true)}>
                            <HiOutlineAdjustments className='text-2xl inline-block float-left'/> Setting
                        </button>
                    </li>
                </ul>
                <p className='absolute bottom-5 left-0 text-center w-full'>Powered By <a href='https://antech.pk' target='_new'>ANTech</a></p>
            </div>
            <Modal onShow={settings} onHide={settingF} mainBtn='Update' mainBtnDisabled={loading} onClick={updatePassword}>
                <h3 className='text-black font-medium text-2xl mb-4'>Setting</h3>
                <hr />
                <div className='my-4 flex gap-3 flex-wrap sm:flex-nowrap'>
                    <Input
                        type={inpuType ? 'text' : 'password'}
                        className="text-gray-800 !px-3 !py-1"
                        onChange={(e)=> setPassword(e.target.value)}
                        value={password}
                        placeholder='Change the password'
                    />
                    <Button 
                        text={inpuType ? <HiOutlineEyeOff className='text-xl'/> : <HiOutlineEye className='text-xl'/>}
                        onClick={inputTypeF}
                        size='big'
                        className="bg-slate-400 hover:bg-slate-500"
                    />
                    <Button text='Generate' size='big' onClick={generatePassword}/>
                </div>
                
            </Modal>
        </>
    )
}
