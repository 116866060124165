export default function Input(props) {
    let { type, placeholder, value, onChange, required, className, defaultValue=null, ...rest} = props;

    const tailCss = 'py-2 px-4 md:py-3 md:px-5 w-full border-2 rounded-lg text-md md:text-lg outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '
    className = className ? tailCss + className : tailCss;
    return (
        <input 
            type={type} 
            placeholder={placeholder} 
            className={className}
            value={value}
            onChange={onChange}
            required={required ? true : false}
            defaultValue={defaultValue}
            {...rest}
        />
    )
}
