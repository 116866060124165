import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import Users from './dashboard/Users';
import MattersAdmin from './dashboard/MattersAdmin';
import Login from './dashboard/Login';
import UserLogin from './Login';
import Matters from './Matter/Matters';
import CreateMatter from './Matter/Create';
import EditMatter from './Matter/Edit';
import Page404 from './404';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path='/login' element={<UserLogin/>}/>
        <Route exact path='/matters' element={<Matters/>}/>
        <Route exact path='/matter/create' element={<CreateMatter/>}/>
        <Route exact path='/matter/:id/edit' element={<EditMatter/>}/>
        <Route exact path='/dashboard' element={<Dashboard/>}/>
        <Route exact path='/dashboard/login' element={<Login/>}/>
        <Route exact path='/dashboard/users' element={<Users/>}/>
        <Route exact path='/dashboard/matters' element={<MattersAdmin/>}/>
        <Route exact path='/' element={<App/>}/>
        <Route exact path='*' element={<Page404/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
