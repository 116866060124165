import React, { useState } from 'react'
import { LuMail, LuMailOpen } from 'react-icons/lu'
import { RiLoader5Line } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { updateNotificationApi } from '../libs/apis'

export default function NotifyList({ data, user }) {
    const [mark, setMark] = useState(data?.active)
    const [loading, setLoading] = useState(false)

    const envalop = async (read) => {
        setLoading(true)
        const res = await updateNotificationApi({ userId: user?.id, role: user?.role, status: read, id: data?.id })
        if (res) {
            setMark(read)
        }
        setLoading(false)
    }


    let notCard = 'p-3 md:p-4 rounded-md relative hover:opacity-70 mb-2'
    return (
        <>
            {user?.role === 39 ? (
                <div className={mark === 1 ? notCard + ' bg-slate-200' : notCard + ' bg-slate-50'} >
                    <div className='absolute top-3 right-3'>
                        {
                            !loading ? (
                                mark === 1 ? (
                                    <LuMail className='text-slate-600 text-lg' title="Mark as read" onClick={_ => envalop(0)} />
                                ) : (
                                    <LuMailOpen className='text-slate-400 text-lg' title="Mark as unread" onClick={_ => envalop(1)} />
                                )
                            ) : <RiLoader5Line className='text-2xl animate-spin text-center mx-auto' />
                        }
                    </div>
                    <strong className='text-slate-600'>{data?.title}</strong>
                    <p className='text-slate-500'>{data?.detail}</p>
                </div>
            ) : (
                <div className={mark === 1 ? notCard + ' bg-slate-200' : notCard + ' bg-slate-50'} >
                    <div className='absolute top-3 right-3'>
                        {
                            mark === 1 ? (
                                <LuMail className='text-slate-600 text-lg cursor-pointer' title="Mark as read" onClick={_ => envalop(0)} />
                            ) : (
                                <LuMailOpen className='text-slate-400 text-lg cursor-pointer' title="Mark as unread" onClick={_ => envalop(1)} />
                            )
                        }
                    </div>
                    <Link to={'/matter/' + data?.matterId + '/edit'} onClick={_ => mark === 1 ? envalop(0) : ''}>
                        <strong className='text-slate-600 cursor-pointer'>{data?.title}</strong>
                    </Link>
                    <p className='text-slate-500'>{data?.detail}</p>
                </div>
            )}
        </>
    )
}
