import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { savePersonalInquiryApi } from '../../libs/apis';
import { RiLoader5Line } from 'react-icons/ri';

let obj = {
    has_any_injuries_occurred: '',
    has_any_injuries_occurred_details: '',
    date_injury_occurred: '',
    gp_or_hospital_attended: '',
    gp_or_hospital_attended_details: '',
}

export default function PersonalInjury({ data, user, matterId }) {
    const [personal, setPersonal] = useState(obj)
    const [loading, setLoading] = useState(false)

    const saveData = async () => {
        toast.loading('Loading...', {
            position: 'bottom-center',
        });
        setLoading(true)
        let data = {
            userId: user.id,
            role: user.role,
            matterId,
            data: personal
        }
        let res = await savePersonalInquiryApi(data)
        if (res?.status) {
            toast.dismiss();
            toast.success('Property record has been updated.', {
                duration: 5000,
                position: 'bottom-center',
            })
            setPersonal(res?.data)
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (data?.id) {
            setPersonal(data)
        } else {
            setPersonal(obj)
        }
    }, [data])

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '

    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7 mt-5'>
                <h3 className='text-lg pb-3 font-semibold'>Personal Injury Details</h3>
                <hr />
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div className='pt-4'>
                        <label htmlFor="has_any_injuries_occurred">Have any injuries occurred due to the disrepair?</label>
                        <select
                            id="has_any_injuries_occurred"
                            name="has_any_injuries_occurred"
                            className={Inputstyle}
                            value={personal?.has_any_injuries_occurred}
                            onChange={e => setPersonal({ ...personal, has_any_injuries_occurred: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        personal?.has_any_injuries_occurred === 'yes' && (
                            <>
                                <div className='pt-4 col-span-2'>
                                    <label htmlFor="has_any_injuries_occurred_details">Details of injuries?</label>
                                    <textarea
                                        id="has_any_injuries_occurred_details"
                                        name="has_any_injuries_occurred_details"
                                        className={Inputstyle}
                                        value={personal?.has_any_injuries_occurred_details}
                                        onChange={e => setPersonal({ ...personal, has_any_injuries_occurred_details: e.target.value })}
                                        rows={3}
                                    ></textarea>
                                </div>
                                <div className='pt-4'>
                                    <label htmlFor="date_injury_occurred">Date injury occurred?</label>
                                    <input
                                        type='date'
                                        id="date_injury_occurred"
                                        name="date_injury_occurred"
                                        className={Inputstyle}
                                        value={personal?.date_injury_occurred}
                                        onChange={e => setPersonal({ ...personal, date_injury_occurred: e.target.value })}
                                    />
                                </div>
                                <div className='pt-4'>
                                    <label htmlFor="gp_or_hospital_attended">Did the injured partie(s) attend the GP or hospital?</label>
                                    <select
                                        id="gp_or_hospital_attended"
                                        name="gp_or_hospital_attended"
                                        className={Inputstyle}
                                        value={personal?.gp_or_hospital_attended}
                                        onChange={e => setPersonal({ ...personal, gp_or_hospital_attended: e.target.value })}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </select>
                                </div>
                                {
                                    personal?.gp_or_hospital_attended === 'yes' && (
                                        <div className='pt-4 col-span-2'>
                                            <label htmlFor="gp_or_hospital_attended_details">Details of GP / hospital visit?</label>
                                            <textarea
                                                id="gp_or_hospital_attended_details"
                                                name="gp_or_hospital_attended_details"
                                                className={Inputstyle}
                                                value={personal?.gp_or_hospital_attended_details}
                                                onChange={e => setPersonal({ ...personal, gp_or_hospital_attended_details: e.target.value })}
                                                rows={3}
                                            ></textarea>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                <hr className='mt-4' />
                <button className='mt-4 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center' onClick={saveData} disabled={loading}>
                    Save
                    {
                        loading && (
                            <RiLoader5Line className='animate-spin' />
                        )
                    }
                </button>
            </div>
        </>
    )
}
