import React, { useEffect, useState } from 'react'
import { saveComplaintsApi } from '../../libs/apis'
import { RiLoader5Line } from 'react-icons/ri'
import toast from 'react-hot-toast'

let obj = {
    has_complained_to_landlord: '',
    first_complained_to_landlord: '',
    complaint_details: '',
    has_copies_of_complaint_or_correspondence: '',
    has_landlord_responded_to_complaints: '',
    landlord_complaints_response: '',
    has_landlord_made_repairs: '',
    what_repairs_made_by_landlord: '',
    where_repairs_satisfactory: '',
    why_repairs_not_satisfactory: ''
}

export default function ComplaintDetails({ data, user, matterId }) {
    const [complaint, setComplaint] = useState(obj)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(data?.id){
            setComplaint(data)
        }
    }, [data])

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '

    const saveData = async () => {
        toast.loading('Loading...',{
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveComplaintsApi({userId:user.id, role: user.role, matterId, data: complaint })
        if(res?.status) {
            toast.dismiss();
            toast.success('Complaint detail has been updated.',{
              duration:5000,
              position: 'bottom-center',
            })
            setComplaint(res?.data)
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message,{
              duration:5000,
              position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7 mt-5'>
                <h3 className='text-lg pb-3 font-semibold'>Complaint Details</h3>
                <hr />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                    <div className='pt-4'>
                        <label htmlFor="has_complained_to_landlord">Have you complained to the landlord?</label>
                        <select
                            id="has_complained_to_landlord"
                            name="has_complained_to_landlord"
                            className={Inputstyle}
                            value={complaint?.has_complained_to_landlord}
                            onChange={e => setComplaint({ ...complaint, has_complained_to_landlord: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        complaint?.has_complained_to_landlord === 'yes' && (
                            <>
                                <div className='pt-4'>
                                    <label htmlFor="first_complained_to_landlord">First complained to landlord?</label>
                                    <input
                                        type="date"
                                        id="first_complained_to_landlord"
                                        name="first_complained_to_landlord"
                                        className={Inputstyle}
                                        value={complaint?.first_complained_to_landlord}
                                        onChange={e => setComplaint({ ...complaint, first_complained_to_landlord: e.target.value })}
                                    />
                                </div>
                                <div className='pt-4 md:col-span-2'>
                                    <label htmlFor="complaint_details">Details of complaint to landlord</label>
                                    <textarea
                                        id="complaint_details"
                                        name="complaint_details"
                                        className={Inputstyle}
                                        value={complaint?.complaint_details}
                                        onChange={e => setComplaint({ ...complaint, complaint_details: e.target.value })}
                                        rows={3}
                                    ></textarea>
                                </div>
                                <div className='pt-4'>
                                    <label htmlFor="has_copies_of_complaint_or_correspondence">Do you have copies of the complaint correspondence?</label>
                                    <select
                                        id="has_copies_of_complaint_or_correspondence"
                                        name="has_copies_of_complaint_or_correspondence"
                                        className={Inputstyle}
                                        value={complaint?.has_copies_of_complaint_or_correspondence}
                                        onChange={e => setComplaint({ ...complaint, has_copies_of_complaint_or_correspondence: e.target.value })}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </select>
                                </div>
                            </>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_landlord_responded_to_complaints">Has landlord responded to complaints?</label>
                        <select
                            id="has_landlord_responded_to_complaints"
                            name="has_landlord_responded_to_complaints"
                            className={Inputstyle}
                            value={complaint?.has_landlord_responded_to_complaints}
                            onChange={e => setComplaint({ ...complaint, has_landlord_responded_to_complaints: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        complaint?.has_landlord_responded_to_complaints === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="landlord_complaints_response">Landlord complaints response?</label>
                                <textarea
                                    id="landlord_complaints_response"
                                    name="landlord_complaints_response"
                                    className={Inputstyle}
                                    value={complaint?.landlord_complaints_response}
                                    onChange={e => setComplaint({ ...complaint, landlord_complaints_response: e.target.value })}
                                    rows={3}
                                ></textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_landlord_made_repairs">Has landlord made repairs?</label>
                        <select
                            id="has_landlord_made_repairs"
                            name="has_landlord_made_repairs"
                            className={Inputstyle}
                            value={complaint?.has_landlord_made_repairs}
                            onChange={e => setComplaint({ ...complaint, has_landlord_made_repairs: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        complaint?.has_landlord_made_repairs === 'yes' && (
                            <>
                                <div className='pt-4 md:col-span-2'>
                                    <label htmlFor="what_repairs_made_by_landlord">What repairs made by landlord?</label>
                                    <textarea
                                        id="what_repairs_made_by_landlord"
                                        name="what_repairs_made_by_landlord"
                                        className={Inputstyle}
                                        value={complaint?.what_repairs_made_by_landlord}
                                        onChange={e => setComplaint({ ...complaint, what_repairs_made_by_landlord: e.target.value })}
                                        rows={3}
                                    ></textarea>
                                </div>
                                <div className='pt-4'>
                                    <label htmlFor="where_repairs_satisfactory">Where repairs satisfactory?</label>
                                    <select
                                        id="where_repairs_satisfactory"
                                        name="where_repairs_satisfactory"
                                        className={Inputstyle}
                                        value={complaint?.where_repairs_satisfactory}
                                        onChange={e => setComplaint({ ...complaint, where_repairs_satisfactory: e.target.value })}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </select>
                                </div>
                                {
                                    complaint?.where_repairs_satisfactory === 'no' && (
                                        <div className='pt-4 md:col-span-2'>
                                            <label htmlFor="why_repairs_not_satisfactory">Why where repairs not satisfactory?</label>
                                            <textarea
                                                id="why_repairs_not_satisfactory"
                                                name="why_repairs_not_satisfactory"
                                                className={Inputstyle}
                                                value={complaint?.why_repairs_not_satisfactory}
                                                onChange={e => setComplaint({ ...complaint, why_repairs_not_satisfactory: e.target.value })}
                                                rows={3}
                                            ></textarea>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                <hr className='mt-4' />
                <button className='mt-4 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center' onClick={saveData} disabled={loading}>
                    Save
                    {
                        loading && (
                            <RiLoader5Line className='animate-spin'/>
                        )
                    }
                </button>
            </div>
        </>
    )
}
