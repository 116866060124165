import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { savePropertyDetailsApi } from '../../libs/apis';
import { RiLoader5Line } from 'react-icons/ri';

let obj = {
    property_type: '',
    number_of_bedrooms: ''
}

export default function PropertyDetails({ data, user, matterId }) {
    const [property, setProperty] = useState(obj)
    const [loading, setLoading] = useState(false)

    const saveData = async () => {
        toast.loading('Loading...',{
            position: 'bottom-center',
        });
        setLoading(true)
        let data = {
            userId:user.id, 
            role: user.role, 
            matterId,
            data: property
        }
        let res = await savePropertyDetailsApi(data)
        if(res?.status) {
            toast.dismiss();
            toast.success('Property record has been updated.',{
              duration:5000,
              position: 'bottom-center',
            })
            setProperty(res?.data)
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message,{
              duration:5000,
              position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    useEffect(() => {
        if(data?.id){
            setProperty(data)
        } else {
            setProperty(obj)
        }
    }, [data])

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '

    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7 mt-5'>
                <h3 className='text-lg pb-3 font-semibold'>Property Detail</h3>
                <hr />
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div className='pt-4'>
                        <label htmlFor="property_type">Property type?</label>
                        <select
                            id="property_type"
                            name="property_type"
                            className={Inputstyle}
                            value={property?.property_type}
                            onChange={e => setProperty({ ...property, property_type: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="Flat">Flat</option>
                            <option value="Terraced">Terraced</option>
                            <option value="Semi-detached">Semi-detached</option>
                            <option value="Detached">Detached</option>
                            <option value="Bungalow">Bungalow</option>
                            <option value="Maisonette">Maisonette</option>
                        </select>
                    </div>
                    <div className='pt-4'>
                        <label htmlFor="number_of_bedrooms">Number of bedrooms</label>
                        <input
                            type="text"
                            id="number_of_bedrooms"
                            name="number_of_bedrooms"
                            className={Inputstyle}
                            value={property?.number_of_bedrooms}
                            onChange={e => setProperty({ ...property, number_of_bedrooms: e.target.value })}
                        />
                    </div>
                </div>
                <hr className='mt-4' />
                <button className='mt-4 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center' onClick={saveData} disabled={loading}>
                    Save
                    {
                        loading && (
                            <RiLoader5Line className='animate-spin' />
                        )
                    }
                </button>
            </div>
        </>
    )
}
