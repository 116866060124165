import React, { useContext, useEffect, useState } from 'react'
import UserLayput from '../UserLayout'
import UserAuth from '../Context/UserAuth/UserContext'
import { getMettersApi } from '../libs/apis'
import Table from '../UI/Table'
import { AiOutlineLoading } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function Matters() {
  return (
    <UserLayput>
      <MattersPage />
    </UserLayput>
  )
}

const status = [
  {
    value: 0,
    label: "Status",
    stages: [
      { value: 0, label: "Select the status" },
    ]
  },
  {
    value: 1,
    label: "New",
    stages: [
      { value: 0, label: "Stage" },
      { value: 10, label: "Appointment Booked" },
      { value: 11, label: "Awaiting Evidence" },
      { value: 12, label: "Awaiting evidence - document collector booked" },
      { value: 13, label: "Awaiting evidence - document collector required" },
      { value: 14, label: "Awaiting evidence - pictures received" },
      { value: 15, label: "Callback arranged" },
      { value: 16, label: "Callback missed" },
      { value: 17, label: "Images Received" },
      { value: 18, label: "No answer" },
      { value: 19, label: "No answer - check timeline" },
      { value: 20, label: "Not Answered Since Case Created" },
      { value: 21, label: "Number already in system" },
      { value: 22, label: "Pictures received" },
      { value: 23, label: "Potential Case" }
    ],
  },
  {
    value: 2,
    label: "Closed",
    stages: [
      { value: 0, label: "Stage" },
      { value: 20, label: "Already claiming" },
      { value: 21, label: "Client Hung Up" },
      { value: 22, label: "Council Actively Involved" },
      { value: 23, label: "Council Have Repaired Property" },
      { value: 24, label: "Did not make enquiry" },
      { value: 25, label: "Disconnected number" },
      { value: 26, label: "Duplicate" },
      { value: 27, label: "Leaving Property" },
      { value: 28, label: "Never Answered" },
      { value: 29, label: "No disrepair" },
      { value: 30, label: "No Prospects Of Success" },
      { value: 31, label: "No reporting history" },
      { value: 32, label: "Not enough damage" },
      { value: 33, label: "Not interested" },
      { value: 34, label: "Pests only" },
      { value: 35, label: "Private tenant" },
      { value: 36, label: "Rent arrears" },
      { value: 37, label: "Repairs already carried out" },
      { value: 38, label: "Scotland" },
      { value: 39, label: "Temporary accommodation" },
      { value: 40, label: "Wrong number" }
    ],
  },
  {
    value: 3,
    label: "Pending",
    stages: [
      { value: 0, label: "Stage" },
      { value: 30, label: "Callback arranged" },
      { value: 31, label: "Callback Rearranged" },
      { value: 32, label: "Case Created" },
      { value: 33, label: "Case Hotkeyed" },
      { value: 34, label: "Case In Vetting" },
      { value: 35, label: "Case sent to solicitor" },
      { value: 36, label: "Case sent to vetting" },
      { value: 37, label: "ESign returned" },
      { value: 38, label: "ESign sent" },
      { value: 39, label: "Hotkey requested" },
      { value: 40, label: "More information required" },
      { value: 41, label: "On call with client" },
      { value: 42, label: "Supervisor Review Required" }
    ],
  },
  {
    value: 4,
    label: "Accepted",
    stages: [
      { value: 0, label: "Stage" },
      { value: 40, label: "Case Accepted" },
      { value: 41, label: "ESign sent" }
    ],
  },
  {
    value: 5,
    label: "Retained",
    stages: [
      { value: 0, label: "Stage" },
      { value: 50, label: "Back On Board" },
      { value: 51, label: "ESign returned" },
      { value: 52, label: "Esign Returned - More Images Required" }
    ],
  },
  {
    value: 6,
    label: "Rejected",
    stages: [
      { value: 0, label: "Stage" },
      { value: 60, label: "Already claiming" },
      { value: 61, label: "Arrears Too High" },
      { value: 62, label: "Client Cancelled Instruction" },
      { value: 63, label: "Client Doesn't Wish To Proceed" },
      { value: 64, label: "Client Instructed Other Solicitors" },
      { value: 65, label: "Duplicate Case" },
      { value: 66, label: "Issues With Quantum" },
      { value: 67, label: "Issues With Reporting History" },
      { value: 68, label: "Not interested" },
      { value: 69, label: "Other (See Notes)" },
      { value: 70, label: "Private Landlord" }
    ],
  },
  {
    value: 7,
    label: "Clawedback",
    stages: [
      { value: 0, label: "Stage" },
      { value: 70, label: "Case Defended" },
      { value: 71, label: "Client Doesn't Wish To Proceed" },
      { value: 72, label: "Client Instructed Other Solicitors" },
      { value: 73, label: "Client Unresponsive" },
      { value: 74, label: "Low Prospects" }
    ]
  }
]


export function MattersPage() {
  const [matters, setMatters] = useState(null)
  const [q, setQ] = useState({ status: 0, stage: 0, page: null })
  const [selectedStatus, setSelectedStatus] = useState(status[0]);
  const [selectedStage, setSelectedStage] = useState(selectedStatus.stages[0]);
  const [date, setDate] = useState({startDate: null, endDate: null})
  const [search, setSearch] = useState('')

  const { user } = useContext(UserAuth)

  const handleStatusChange = (event) => {
    const selectedStatusValue = parseInt(event.target.value, 10);
    const selectedStatus = status.find(status => status.value === selectedStatusValue);
    setSelectedStatus(selectedStatus);
    setSelectedStage(selectedStatus.stages[0]);
    selectedStatusValue > 0 ? setQ({ ...q, stage: 0, page: null, status: selectedStatusValue }) : setQ({ status: 0, stage: 0, page: null })
  };

  const handleStageChange = (event) => {
    const selectedStageValue = parseInt(event.target.value, 10);
    const selectedStage = selectedStatus.stages.find(stage => stage.value === selectedStageValue);
    setSelectedStage(selectedStage);
    selectedStageValue > 0 ? setQ({ ...q, stage: selectedStageValue }) : setQ({ ...q, stage: 0 })
  };


  useEffect(() => {
    async function getAllMatters(userId, role, status, stage, startDate, endDate, page, search) {
      setMatters(null)
      let res = await getMettersApi(userId, role, status, stage, startDate, endDate, page, search)
      setMatters(res)
    }
    getAllMatters(user?.id, user?.role, q.status, q.stage, date?.startDate, date?.endDate, q.page, search)
  }, [user, q, date, search])

  return (
    <>
      <div className='w-full mb-5'>
        <h2 className='text-2xl font-semibold mb-5'>Matters</h2>
      </div>
      <div className='flex gap-5 mb-5 justify-between items-center border-2 flex-wrap md:flex-nowrap border-slate-100 rounded-lg shadow-lg bg-white p-4 md:px-5'>
        <div className="flex gap-5 pb-2 md:p-0 overflow-auto " >
          {(q.status > 0 || date.startDate) && <button onClick={_ => {
            setQ({ status: 0, stage: 0, page: null })
            setSelectedStatus(status[0])
            setSelectedStage(selectedStatus.stages[0])
            setDate({startDate: null, endDate: null})
          }} className='underline'>Clear</button>}
          <select id="statusSelect" value={q?.status} onChange={handleStatusChange} className="max-w-[200px] py-1 px-2 w-auto border-2 border-slate-300 rounded-md text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 ">
            {status.map(status => (
              <option key={status.value} value={status.value}>{status.label}</option>
            ))}
          </select>
          {
            q?.status > 0 && (
              <select id="stageSelect" value={selectedStage.value} onChange={handleStageChange} className="max-w-[200px] py-1 px-2 w-auto border-2 border-slate-300 rounded-md text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 ">
                {selectedStatus.stages.map(stage => (
                  <option key={stage.value} value={stage.value}>{stage.label}</option>
                ))}
              </select>
            )
          }
          <div className='flex gap-1 items-center'>
            <label htmlFor="startDate">From: </label>
            <input type="date" value={date?.startDate ? date?.startDate : ''} onChange={e => {
              setDate({...date, startDate: e.target.value})
              setQ({...q, page: null})
              }} name="startDate" id="startDate" className='max-w-[300px] py-1 px-2 w-auto border-2 border-slate-300 rounded-md text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '/>
          </div>
          <div className='flex gap-1 items-center'>
            <label htmlFor="endDate">To: </label>
            <input type="date" value={date?.endDate ? date?.endDate : ''} onChange={e => {
              setDate({...date, endDate: e.target.value})
              setQ({...q, page: null})
              }} name="endDate" id="endDate" className='max-w-[300px] py-1 px-2 w-auto border-2 border-slate-300 rounded-md text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '/>
          </div>
        </div>
        <div className='flex gap-3 w-full md:w-[250px]'>
          <input type="search" name="search" id="search" value={search} onChange={e => { 
            setSearch(e.target.value)
            setQ({...q, page: null})
          }} placeholder='Enter reference #' className='w-full py-1 px-2 border-2 border-slate-300 rounded-md text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '/>
        </div>
      </div>
      <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-10'>
        <Table heading={['Reference', 'Client', 'Status', 'Product', 'UpdatedAt']} className="text-left">
          {!matters?.data?.length > 0 && (
            <tr>
              <td colSpan="5" className='py-3'>
                <AiOutlineLoading className='animate-spin text-2xl mx-auto' />
              </td>
            </tr>)
          }
          {!matters?.status && (
            <tr>
              <td colSpan="5" className='py-3'>
                {matters?.data[0].error}
              </td>
            </tr>)
          }
          {
            matters?.status && matters?.data?.map(m => {
              if (!m.status) {
                return <tr><td colSpan="5" className='py-3'>{m.message}</td></tr>
              }
              let type = ''
              switch (m?.type) {
                case '1':
                  type = 'HDR'
                  break;

                default:
                  type = 'None'
              }
              let statusBg = ''
              switch (m?.status) {
                case '1':
                  statusBg = 'bg-purple-600'
                  break;
                case '2':
                  statusBg = 'bg-green-600'
                  break;
                case '3':
                  statusBg = 'bg-orange-600'
                  break;
                case '4':
                  statusBg = 'bg-yellow-600'
                  break;
                case '5':
                  statusBg = 'bg-blue-600'
                  break;
                default:
                  statusBg = 'bg-pink-600'
              }
              const stg = status?.filter(s => parseInt(s.value) === parseInt(m?.status))[0]?.stages.filter(s => parseInt(s.value) === parseInt(m?.stage));
              return (

                <tr key={m.uniqueId} className='border-b-[1px] hover:bg-slate-100 transition-all duration-300'>
                  <td className='p-3 min-w-[100px]'>
                    {
                      user.role === 39 ? (
                        m.reference
                      ) : (
                        <Link to={`/matter/${m.uniqueId}/edit`} className='text-blue-500 hover:underline'>
                          {m.reference}
                        </Link>
                      )
                    }
                  </td>
                  <td className='p-3 min-w-[100px]'>{m.title} {m.first_name} {m.last_name}</td>
                  <td className='p-3 min-w-[100px]'>
                    <span className={'p-3 py-1 text-white rounded-full text-sm ' + statusBg}>
                      {
                        status?.filter(s => parseInt(s.value) === parseInt(m?.status))[0].label + (stg[0].value > 0 ? ' - ' + stg[0].label : '')
                      }
                    </span>
                  </td>
                  <td className='p-3 min-w-[100px]'>{type}</td>
                  <td className='p-3 min-w-[100px]'>{m.updatedAt}</td>
                </tr>
              )
            })
          }
        </Table>
        {
          matters?.pagination && (
            <div className='flex justify-between mt-5'>
              <div className='flex gap-2'>
                <p>Page:</p>
                {
                  matters?.pagination.prevPage && (
                    <button className='bg-slate-300 hover:bg-slate-400 transition-all text-sm py-1 px-3 rounded' onClick={_ => setQ({ ...q, page: matters?.pagination.prevPage })} >{matters?.pagination.prevPage}</button>
                  )
                }
                <button className='bg-purple-600 text-white text-sm py-1 px-3 rounded'>{matters?.pagination.currentPage}</button>
                {
                  matters?.pagination.nextPage && (
                    <button className='bg-slate-300 hover:bg-slate-400 transition-all text-sm py-1 px-3 rounded' onClick={_ => setQ({ ...q, page: matters?.pagination.nextPage })} >{matters?.pagination.nextPage}</button>
                  )
                }
              </div>
              <div>Total Records: {matters?.pagination.totalRecords}</div>
            </div>
          )
        }
      </div>
    </>
  )
}