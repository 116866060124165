import React from 'react'
import { Chart } from "react-google-charts";

export const options = {
    title: "Users",
    pieHole: 0.4,
    is3D: false,
  };
  

export default function PieChart({data}) {
  if (!data) return <p>Loading...</p>
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  )
}
