import React, { useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai';
import Switch from "react-switch";
import { updateUserStatusApi } from '../libs/apis';
import toast from 'react-hot-toast';

export default function StatusSwitch({ admin, id, status }) {
    const [st, setSt] = useState(status)
    const [loading, setLoading] = useState(false)

    const updateStatus = async (id, status) => {
        setLoading(true)
        status = status ? 0 : 1
        const data = {
            id,
            status,
            adminUniqueId: admin?.id,
            adminRole: admin?.role,
        }
        const res = await updateUserStatusApi(data)
        if (res?.status) {
            toast.dismiss();
            toast.success(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
            setSt(status)
        } else {
            toast.dismiss();
            toast.error(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
    }
    if (loading) {
        return <AiOutlineLoading className='animate-spin mx-auto' />
    }
    return (
        <Switch onChange={_ => updateStatus(id, st)} onColor="#581C87" checked={st} />
    )
}
