import React, { useEffect, useState } from 'react'
import { AiOutlinePound } from 'react-icons/ai';
import { RiLoader5Line } from 'react-icons/ri'
import { saveTenancyDetails } from '../../libs/apis';
import toast from 'react-hot-toast';

const obj = {
    tenancy_start_date: '',
    has_copy_of_agreement: '',
    single_or_joint_tenancy: '',
    is_named_in_tenancy: '',
    has_paid_deposit: '',
    deposit_amount: '',
    deposit_was_protected: '',
    rental_amount: '',
    rental_frequency: '',
    receives_housing_benefit: '',
    housing_benefit_amount: '',
    housing_benefit_paid_to: '',
    has_rental_arrears: '',
    rental_arrears_amount: '',
    has_payment_plan: '',
    payment_plan_amount: '',
    payment_plan_frequency: '',
    why_case: '',
}

export default function TenancyDetails({ data, user, matterId }) {
    const [tenancy, setTenancy] = useState(obj)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(data?.id) {
            setTenancy(data)
        } else {
            setTenancy(obj)
        }
    }, [data])
    
    const saveData = async () => {
        toast.loading('Loading...',{
            position: 'bottom-center',
        });
        setLoading(true)
        let data = {
            matterId,
            userId: user.id,
            role: user.role, 
            data: tenancy
        }
        const res = await saveTenancyDetails(data)
        if(res?.status) {
            toast.dismiss();
            toast.success('Tenancy record has been updated.',{
                duration:5000,
                position: 'bottom-center',
            })
            setTenancy(res?.data)
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message,{
                duration:5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '

    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7 mt-5'>
                <div className='pt-4'>
                    <label htmlFor="why_case">Why we believe this is a case</label>
                    <textarea
                        placeholder='Detail'
                        id="why_case"
                        name="why_case"
                        className={Inputstyle}
                        onChange={e => setTenancy({ ...tenancy, why_case: e.target.value })}
                        rows={4}
                        value={tenancy?.why_case}
                    >
                    </textarea>
                </div>
                <h3 className='text-lg font-semibold pb-3 mt-4'>Tenancy Details</h3>
                <hr />
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4'>
                    <div className='pt-4'>
                        <label htmlFor="startDate">Tenancy start date</label>
                        <input
                            type="date"
                            id="startDate"
                            name="start_date"
                            className={Inputstyle}
                            value={tenancy?.tenancy_start_date}
                            onChange={e => setTenancy({ ...tenancy, tenancy_start_date: e.target.value })}
                        />
                    </div>
                    <div className='pt-4'>
                        <label htmlFor="has_copy_of_agreement">Do you have a copy of your tenancy agreement?</label>
                        <select
                            id="has_copy_of_agreement"
                            name="has_copy_of_agreement"
                            className={Inputstyle}
                            value={tenancy?.has_copy_of_agreement}
                            onChange={e => setTenancy({ ...tenancy, has_copy_of_agreement: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    <div className='pt-4'>
                        <label htmlFor="single_or_joint_tenancy">Is the tenancy single or joint?</label>
                        <select
                            id="single_or_joint_tenancy"
                            name="single_or_joint_tenancy"
                            className={Inputstyle}
                            value={tenancy?.single_or_joint_tenancy}
                            onChange={e => setTenancy({ ...tenancy, single_or_joint_tenancy: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="singel">Single</option>
                            <option value="joint">Joint</option>
                        </select>
                    </div>
                    <div className='pt-4'>
                        <label htmlFor="is_named_in_tenancy">Are you named in the tenancy?</label>
                        <select
                            id="is_named_in_tenancy"
                            name="is_named_in_tenancy"
                            className={Inputstyle}
                            value={tenancy?.is_named_in_tenancy}
                            onChange={e => setTenancy({ ...tenancy, is_named_in_tenancy: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    <div className='pt-4'>
                        <label htmlFor="has_paid_deposit">Did you pay a deposit?</label>
                        <select
                            id="has_paid_deposit"
                            name="has_paid_deposit"
                            className={Inputstyle}
                            value={tenancy?.has_paid_deposit}
                            onChange={e => setTenancy({ ...tenancy, has_paid_deposit: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        tenancy?.has_paid_deposit === 'yes' && (
                            <>
                                <div className='pt-4'>
                                    <label htmlFor="deposit_amount" className='flex gap-2 items-center'>If so, how much? <AiOutlinePound className='text-2xl'/></label>
                                    <input
                                        type='number'
                                        id="deposit_amount"
                                        name="deposit_amount"
                                        className={Inputstyle}
                                        value={tenancy?.deposit_amount}
                                        onChange={e => setTenancy({ ...tenancy, deposit_amount: e.target.value })}
                                    />
                                </div>
                                <div className='pt-4'>
                                    <label htmlFor="deposit_was_protected">Was your deposit placed in a deposit protection scheme?</label>
                                    <select
                                        id="deposit_was_protected"
                                        name="deposit_was_protected"
                                        className={Inputstyle}
                                        value={tenancy?.deposit_was_protected}
                                        onChange={e => setTenancy({ ...tenancy, deposit_was_protected: e.target.value })}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="no">No</option>
                                        <option value="not sure">Not Sure</option>
                                        <option value="yes">Yes</option>
                                    </select>
                                </div>
                            </>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="rental_amount" className='flex gap-2 items-center'>How much rent do you pay? <AiOutlinePound className='text-2xl'/></label>
                        <input
                            type='number'
                            id="rental_amount"
                            name="rental_amount"
                            className={Inputstyle}
                            value={tenancy?.rental_amount}
                            onChange={e => setTenancy({ ...tenancy, rental_amount: e.target.value })}
                        />
                    </div>
                    <div className='pt-4'>
                        <label htmlFor="rental_frequency">How often is this paid?</label>
                        <select
                            id="rental_frequency"
                            name="rental_frequency"
                            className={Inputstyle}
                            value={tenancy?.rental_frequency}
                            onChange={e => setTenancy({ ...tenancy, rental_frequency: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Fortnightly">Fortnightly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Every four weeks">Every four weeks</option>
                        </select>
                    </div>
                    <div className='pt-4'>
                        <label htmlFor="receives_housing_benefit">Are you in receipt of housing benefit?</label>
                        <select
                            id="receives_housing_benefit"
                            name="receives_housing_benefit"
                            className={Inputstyle}
                            value={tenancy?.receives_housing_benefit}
                            onChange={e => setTenancy({ ...tenancy, receives_housing_benefit: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        tenancy?.receives_housing_benefit === 'yes' && (
                            <>
                                <div className='pt-4'>
                                    <label htmlFor="housing_benefit_amount" className='flex gap-2 items-center'>if so, how much do you receive? <AiOutlinePound className='text-2xl'/></label>
                                    <input
                                        type='number'
                                        id="housing_benefit_amount"
                                        name="housing_benefit_amount"
                                        className={Inputstyle}
                                        value={tenancy?.housing_benefit_amount}
                                        onChange={e => setTenancy({ ...tenancy, housing_benefit_amount: e.target.value })}
                                    />
                                </div>
                                <div className='pt-4'>
                                    <label htmlFor="housing_benefit_paid_to">Is this paid to your or to your landlord?</label>
                                    <select
                                        id="housing_benefit_paid_to"
                                        name="housing_benefit_paid_to"
                                        className={Inputstyle}
                                        value={tenancy?.housing_benefit_paid_to}
                                        onChange={e => setTenancy({ ...tenancy, housing_benefit_paid_to: e.target.value })}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="To me">To me</option>
                                        <option value="To landlord">To landlord</option>
                                    </select>
                                </div>
                            </>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_rental_arrears">Do you have any arrears?</label>
                        <select
                            id="has_rental_arrears"
                            name="has_rental_arrears"
                            className={Inputstyle}
                            value={tenancy?.has_rental_arrears}
                            onChange={e => setTenancy({ ...tenancy, has_rental_arrears: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        tenancy?.has_rental_arrears === 'yes' && (
                            <>
                                <div className='pt-4'>
                                    <label htmlFor="rental_arrears_amount" className='flex gap-2 items-center'>if so, how much? <AiOutlinePound className='text-2xl'/></label>
                                    <input
                                        type='number'
                                        id="rental_arrears_amount"
                                        name="rental_arrears_amount"
                                        className={Inputstyle}
                                        value={tenancy?.rental_arrears_amount}
                                        onChange={e => setTenancy({ ...tenancy, rental_arrears_amount: e.target.value })}
                                    />
                                </div>
                                <div className='pt-4'>
                                    <label htmlFor="has_payment_plan">Do you have a payment plan setup?</label>
                                    <select
                                        id="has_payment_plan"
                                        name="has_payment_plan"
                                        className={Inputstyle}
                                        value={tenancy?.has_payment_plan}
                                        onChange={e => setTenancy({ ...tenancy, has_payment_plan: e.target.value })}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </select>
                                </div>
                                {
                                    tenancy?.has_payment_plan === 'yes' && (
                                        <>
                                        <div className='pt-4'>
                                            <label htmlFor="payment_plan_amount" className='flex gap-2 items-center'>if so, how much? <AiOutlinePound className='text-2xl'/></label>
                                            <input
                                                type='number'
                                                id="payment_plan_amount"
                                                name="payment_plan_amount"
                                                className={Inputstyle}
                                                value={tenancy?.payment_plan_amount}
                                                onChange={e => setTenancy({ ...tenancy, payment_plan_amount: e.target.value })}
                                            />
                                        </div>
                                        <div className='pt-4'>
                                        <label htmlFor="payment_plan_frequency">How often is this paid?</label>
                                            <select
                                                id="payment_plan_frequency"
                                                name="payment_plan_frequency"
                                                className={Inputstyle}
                                                value={tenancy?.payment_plan_frequency}
                                                onChange={e => setTenancy({ ...tenancy, payment_plan_frequency: e.target.value })}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Fortnightly">Fortnightly</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Every four weeks">Every four weeks</option>
                                            </select>
                                        </div>
                                        </>
                                    )}
                            </>
                        )
                    }
                </div>
                
                <hr className='mt-4' />
                <button className='mt-4 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center' onClick={saveData} disabled={loading}>
                    Save
                    {
                        loading && (
                            <RiLoader5Line className='animate-spin'/>
                        )
                    }
                </button>
            </div>
        </>
    )
}
