import React, { useContext, useEffect, useState } from 'react'
import UserLayout from '../UserLayout'
import { useParams } from 'react-router-dom'
import { getClaimApi, getClientApi, getComplaintsApi, getFilesApi, getHousingDisrepairApi, getLandlordApi, getMatterOneApi, getPersonalInquiryApi, getPropertyDetailsApi, getRoomsApi } from '../libs/apis'
import UserAuth from '../Context/UserAuth/UserContext'
import ClientForm from './Components/ClientForm'
import Landlord from './Components/Landlord'
import Files from './Components/Files'
import TenancyDetails from './Components/TenancyDetails'
import PropertyDetails from './Components/PropertyDetails'
import ClaimDetails from './Components/ClaimDetails'
import Rooms from './Components/Rooms'
import ComplaintDetails from './Components/ComplaintDetails'
import PersonalInjury from './Components/PersonalInjury'
import SideBar from './Components/SideBar'

export default function EditMatter() {
    const params = useParams()
    return (
        <UserLayout>
            <EditMatterPage id={params.id}/>
        </UserLayout>
    )
}

export function EditMatterPage({ id }) {
    const [matter, setMatter] = useState({})
    const [client, setClient] = useState({})
    const [landload, setLandload] = useState({})
    const [files, setFiles] = useState({})
    const [tenancy, setTenancy] = useState({})
    const [property, setProperty] = useState({})
    const [claim, setClaim] = useState({})
    const [rooms, setRooms] = useState([])
    const [complaint, setComplaint] = useState({})
    const [personal, setPersonal] = useState({})

    const { user } = useContext(UserAuth)

    useEffect(()=>{
        let getMatter= async (user, id) => { 
            const getMatterData = await getMatterOneApi({userId:user.id, role: user.role, matterId: id })
            if(getMatterData?.status) {
                setMatter(getMatterData)
            }
        }
        let getClient = async (user, id) => { 
            const getClientData = await getClientApi({userId:user.id, role: user.role, matterId: id })
            if(getClientData?.status) {
                setClient(getClientData)
            }
        }
        let getLandlordData = async (user, id) => { 
            const getLandlordData = await getLandlordApi({userId:user.id, role: user.role, matterId: id })
            if(getLandlordData?.status) {
                setLandload(getLandlordData)
            }
        }
        let getFilesData = async (user, id) => { 
            const getFilesData = await getFilesApi({userId:user.id, role: user.role, matterId: id })
            if(getFilesData?.status) {
                setFiles(getFilesData)
            }
        }
        let getHousingDisrepairData = async (user, id) => { 
            const getData = await getHousingDisrepairApi({userId:user.id, role: user.role, matterId: id })
            if(getData?.status) {
                setTenancy(getData)
            }
        }
        let getPropertyDetailsData = async (user, id) => { 
            const getData = await getPropertyDetailsApi({userId:user.id, role: user.role, matterId: id })
            if(getData?.status) {
                setProperty(getData)
            }
        }
        let getClaimData = async (user, id) => { 
            const getData = await getClaimApi({userId:user.id, role: user.role, matterId: id })
            if(getData?.status) {
                setClaim(getData)
            }
        }
        let getRoomsData = async (user, id) => { 
            const getData = await getRoomsApi({userId:user.id, role: user.role, matterId: id })
            if(getData?.status) {
                setRooms(getData)
            }
        }
        let getComplaintsData = async (user, id) => { 
            const getData = await getComplaintsApi({userId:user.id, role: user.role, matterId: id })
            if(getData?.status) {
                setComplaint(getData)
            }
        }
        let getPersonalInquiryData = async (user, id) => { 
            const getData = await getPersonalInquiryApi({userId:user.id, role: user.role, matterId: id })
            if(getData?.status) {
                setPersonal(getData)
            }
        }

        getMatter(user, id)
        getPersonalInquiryData(user, id)
        getComplaintsData(user, id)
        getRoomsData(user, id)
        getClaimData(user, id)
        getPropertyDetailsData(user, id)
        getHousingDisrepairData(user, id)
        getFilesData(user, id)
        getLandlordData(user, id)
        getClient(user, id)
    }, [user, id])
    return (
        <div className='lg:flex gap-5'>
            <div className='mt-12 lg:mt-0 w-full'>
                <ClientForm data={client?.data} user={user}/>
                <Landlord data={landload?.data} user={user} matterId={id}/>
                <Files user={user} matterId={id} data={files?.data}/>
                <TenancyDetails user={user} matterId={id} data={tenancy?.data}/>
                <PropertyDetails user={user} matterId={id} data={property?.data}/>
                <ClaimDetails user={user} matterId={id} data={claim?.data}/>
                <Rooms user={user} matterId={id} data={rooms?.data}/>
                <ComplaintDetails user={user} matterId={id} data={complaint?.data}/>
                <PersonalInjury user={user} matterId={id} data={personal?.data}/>
            </div>
            <div className='w-[25%]'>
                <SideBar user={user} matterId={id} data={matter?.data}/>
            </div>
        </div>
    )
}


