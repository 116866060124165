import React, { useCallback, useEffect, useState } from 'react'
import { imageFetcher, saveFilesApi } from '../../libs/apis'
import toast from 'react-hot-toast'
import { useDropzone } from 'react-dropzone'
import { CiImageOn, CiMusicNote1, CiVideoOn, CiFileOn, CiText } from "react-icons/ci";
import { AiOutlineFileUnknown } from "react-icons/ai";

import { RxDownload } from "react-icons/rx";
import FileCard from './FileCard'
import JSZip from 'jszip';
import { RiLoader5Line } from 'react-icons/ri';

const apiDomain = process.env.REACT_APP_API_DOMAIN

export default function Files({ data, user, matterId }) {
    const [files, setFiles] = useState(null)
    const [loading, setLoading] = useState(false)

    const onDrop = useCallback(acceptedFiles => {
        const saveFiles = async (files) => {
            const formData = new FormData();
            toast.loading('Loading...', {
                position: 'bottom-center',
            });
            setLoading(true)
            files.forEach((file, index) => {
                formData.append(`file[${index}]`, file);
            });
            // formData.append('file[]', files)
            formData.append('matterId', matterId)
            formData.append('userId', user.id)
            formData.append('role', user.role)
            const res = await saveFilesApi(formData)
            toast.dismiss();
            if (res?.status) {
                let msg = acceptedFiles.length > 1 ? `(${acceptedFiles.length}) Files has been uploaded.` : `(${acceptedFiles.length}) File has been uploaded.`;
                toast.success(msg, {
                    duration: 5000,
                    position: 'bottom-center',
                })
                setFiles(res?.data)
                setLoading(false)
            } else {
                toast.dismiss();
                toast.error(res?.message, {
                    duration: 5000,
                    position: 'bottom-center',
                })
                setLoading(false)
            }
        }
        saveFiles(acceptedFiles)
    }, [user, matterId])

    const { getRootProps, getInputProps } = useDropzone({ onDrop, disabled: loading });

    useEffect(() => {
        setFiles(data)
    }, [data])

    const downloadAllFiles = async () => {
        // Create a new instance of JSZip
        const zip = new JSZip();
        setLoading(true)
        toast.loading('Creating download file...', {
            position: 'bottom-center',
        });
        // Iterate through all files
        for (const file of files) {
            // Fetch the file content
            const blob = await imageFetcher(apiDomain + file.file_url);
            // Add the file to the zip archive with a custom name
            zip.file(file.alt, blob);
        }

        // Generate the zip file
        await zip.generateAsync({ type: 'blob' })
        .then(zip => {
            // Create a link for downloading the zip file
            let date = new Date();
            date = date.getTime() / 1000;
            const link = document.createElement('a');
            link.href = URL.createObjectURL(zip);
            link.download = `all_files_${date}.zip`;
            link.click();
            setLoading(false)
            toast.dismiss();
            toast.success('Zip file downloaded successfully.', {
                duration: 5000,
                position: 'bottom-center',
            })
        })
        .catch(err => {
            setLoading(false)
            toast.dismiss();
            toast.success(err.message, {
                duration: 5000,
                position: 'bottom-center',
            })
        });
        setLoading(false)
        
    };

    const dataRefresh = (data) => {
        setFiles(data);
    }

    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7 mt-5'>
                <h3 className='text-lg pb-3 font-semibold'>Files</h3>
                <hr />
                <section className="">
                    <div {...getRootProps({ className: 'dropzone' })} className='w-full h-[150px] border-2 mt-4 rounded-md border-dashed border-slate-300 hover:border-slate-400 cursor-pointer transition-all duration-300 flex justify-center items-center text-slate-300 hover:text-slate-400'>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                </section>
                {
                    files?.length > 0 && (
                        <>
                            <div className='w-full mt-4 border border-slate-300 min-h-[50px] rounded-lg p-3 lg:p-6 grid-cols-2 sm:grid-cols-3 grid md:grid-cols-4 lg:grid-cols-5 gap-2'>
                                {
                                    files.map((f, i) => {
                                        const fileType = f.type.split('/')[0]
                                        let fileIcon;
                                        switch (fileType) {
                                            case 'image':
                                                fileIcon = <CiImageOn />;
                                                break;
                                            case 'video':
                                                fileIcon = <CiVideoOn />;
                                                break;
                                            case 'audio':
                                                fileIcon = <CiMusicNote1 />;
                                                break;
                                            case 'application':
                                                fileIcon = <CiFileOn />;
                                                break;
                                            case 'text':
                                                fileIcon = <CiText />;
                                                break;
                                            default:
                                                fileIcon = <AiOutlineFileUnknown />;
                                                break;
                                        }
                                        
                                        return (
                                            <FileCard key={i} data={f} icon={fileIcon} dataReturn={dataRefresh} user={user} matterId={matterId} type={fileType}/>
                                        )
                                        
                                    })
                                }
                            </div>
                            <hr className='mt-4' />
                            <button
                                className='mt-4 py-2 px-4 md:py-2 md:px-5 bg-purple-800 rounded-md text-white text-lg hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center'
                                onClick={downloadAllFiles}
                                disabled={loading}
                            >
                                { loading ? <RiLoader5Line className='animate-spin' /> : <RxDownload />} All Download
                            </button>
                        </>
                    )
                }
            </div>
        </>
    )
}
