import React, { useContext, useEffect, useState } from 'react'
import { CiCircleCheck, CiMedicalCase, CiTimer } from "react-icons/ci";
import { LiaPoundSignSolid } from "react-icons/lia";
import { SlReload } from "react-icons/sl";
import { getReportApi } from '../libs/apis';
import UserAuth from '../Context/UserAuth/UserContext';

export default function DashboardReport() {
    const [date, setDate] = useState({ startDate: null, endDate: null })
    const [loading, setLoading] = useState(false)
    const [report, setReport] = useState(null)

    const { user } = useContext(UserAuth)

    async function getReportData(data){
        setLoading(true)
        let res = await getReportApi(data)
        console.log(res);
        if(res?.status){
            setReport(res?.data)
        } else {
            setReport(null)
        }
        setLoading(false)
    }

    useEffect(()=>{
        getReportData({userId: user?.id, startDate: date?.startDate, endDate: date?.endDate })
    },[user, date])

    const refresh = async () => {
        await getReportData({userId: user?.id, startDate: date?.startDate, endDate: date?.endDate })
    }

    return (
        <>
            <div className='flex gap-5 mb-5 items-center border-2 flex-wrap md:flex-nowrap border-slate-100 rounded-lg shadow-lg bg-white p-4 md:px-5'>
                <h3 className='text-lg font-semibold'>Report: </h3>
                <div className='flex gap-1 items-center'>
                    <label htmlFor="startDate">From: </label>
                    <input type="date" value={date?.startDate ? date?.startDate : ''} onChange={e => {
                        setDate({ ...date, startDate: e.target.value })
                    }} name="startDate" id="startDate" className='max-w-[300px] py-1 px-2 w-auto border-2 border-slate-300 rounded-md text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 ' />
                </div>
                <div className='flex gap-1 items-center'>
                    <label htmlFor="endDate">To: </label>
                    <input type="date" value={date?.endDate ? date?.endDate : ''} onChange={e => {
                        setDate({ ...date, endDate: e.target.value })
                    }} name="endDate" id="endDate" className='max-w-[300px] py-1 px-2 w-auto border-2 border-slate-300 rounded-md text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 ' />
                </div>
                <button title='Refresh' onClick={_ => {
                    setDate({ startDate: null, endDate: null })
                    refresh()
                }} className='p-3 border border-slate-200 rounded-md text-slate-500 hover:text-slate-400'><SlReload /></button>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5'>
                <div className='flex gap-5 border-2 border-slate-100 rounded-lg shadow-lg bg-white p-4 md:px-5'>
                    <div className='flex justify-center items-center bg-purple-600 rounded-md text-white text-3xl p-3'>
                        <CiMedicalCase />
                    </div>
                    <div>
                        <span>New</span>
                        {
                            loading ? (
                                <div className='font-semibold bg-slate-300 animate-pulse w-[50px] h-[26px] mt-2 rounded-sm'></div>
                            ) : (
                                <h3 className='text-3xl font-semibold'>{ report?.new || '0' }</h3>
                            )
                        }
                    </div>
                </div>
                <div className='flex gap-5 border-2 border-slate-100 rounded-lg shadow-lg bg-white p-4 md:px-5'>
                    <div className='flex justify-center items-center bg-green-400 rounded-md text-white text-3xl p-3'>
                        <CiTimer />
                    </div>
                    <div>
                        <span>Pending</span>
                        {
                            loading ? (
                                <div className='font-semibold bg-slate-300 animate-pulse w-[50px] h-[26px] mt-2 rounded-sm'></div>
                            ) : (
                                <h3 className='text-3xl font-semibold'>{ report?.pending || '0' }</h3>
                            )
                        }
                    </div>
                </div>
                <div className='flex gap-5 border-2 border-slate-100 rounded-lg shadow-lg bg-white p-4 md:px-5'>
                    <div className='flex justify-center items-center bg-orange-600 rounded-md text-white text-3xl p-3'>
                        <CiCircleCheck />
                    </div>
                    <div>
                        <span>Accepted</span>
                        {
                            loading ? (
                                <div className='font-semibold bg-slate-300 animate-pulse w-[50px] h-[26px] mt-2 rounded-sm'></div>
                            ) : (
                                <h3 className='text-3xl font-semibold'>{ report?.accepted || '0' }</h3>
                            )
                        }
                    </div>
                </div>
                <div className='flex gap-5 border-2 border-slate-100 rounded-lg shadow-lg bg-white p-4 md:px-5'>
                    <div className='flex justify-center items-center bg-red-400 rounded-md text-white text-3xl p-3'>
                        <LiaPoundSignSolid />
                    </div>
                    <div>
                        <span>Retained</span>
                        {
                            loading ? (
                                <div className='font-semibold bg-slate-300 animate-pulse w-[50px] h-[26px] mt-2 rounded-sm'></div>
                            ) : (
                                <h3 className='text-3xl font-semibold'>{ report?.retained || '0' }</h3>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
