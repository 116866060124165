import React, { useEffect, useState } from 'react'
import { saveRoomsApi } from '../../libs/apis'
import { RiLoader5Line } from 'react-icons/ri'
import toast from 'react-hot-toast'
import { CiEdit, CiTrash } from 'react-icons/ci'
import Modal from '../../UI/Modal'
import Button from '../../UI/Button'

let obj = {
    matterId: '',
    type: '',
    name: '',
    details: ''
}
export default function Rooms({ data, user, matterId }) {
    const [rooms, setRooms] = useState([])
    const [loading, setLoading] = useState(false)
    const [roomModal, setRoomModal] = useState(false)
    const [singleRoom, setSingleRoom] = useState(obj)
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        if (data?.length > 0) {
            setRooms(data)
        }
    }, [data])

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '

    const saveData = async () => {
        toast.loading('Loading...', {
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveRoomsApi({userId:user.id, role: user.role, matterId, data: rooms })
        if (res?.status) {
            toast.dismiss();
            toast.success('Rooms record has been updated.', {
                duration: 5000,
                position: 'bottom-center',
            })
            setRooms(res?.data)
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    const editRoom = async (id) => {
        let arr = rooms
        arr = arr.filter((e, i) => e.id === id)

        setSingleRoom(arr[0])
        setRoomModal(true)
        setUpdate(true)
    }

    const deleteRoom = async (id) => {
        let arr = rooms
        if (window.confirm('Are you sure?')) {
            arr = arr.filter((e, i) => e.id !== id)
            setRooms(arr)
        }
    }

    const roomModalF = () => {
        setRoomModal(false)
        setSingleRoom(obj)
        setUpdate(false)
    }

    const addRoom = async () => {
        if (singleRoom.name && singleRoom.type && singleRoom.details) {
            let data = singleRoom
            data['matterId'] = matterId
            let ids = rooms.length > 0 ? rooms.reduce((a, b) => {
                return b['id'] > a['id'] ? b : a;
              }, rooms[0]) : 0;
            data['id'] = parseFloat(ids.id) + 1
            setRooms([...rooms, data])
            setSingleRoom(obj)
            setRoomModal(false)
        } else {
            alert('Add room detail first.')
        }
    }

    const updateRoom = async () => {
        let arr = rooms
        arr = arr.filter(r => r.id !== singleRoom.id)
        arr = [...arr, singleRoom]
        setRooms(arr)
        setRoomModal(false)
        setSingleRoom(obj)
    }

    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7 mt-5'>
                <div className='flex justify-between items-center mb-3'>
                    <h3 className='text-lg pb-3 font-semibold'>Rooms</h3>
                    <Button type='button' text="Add Room" size='small' onClick={_ => setRoomModal(true)}/>
                </div>
                <hr />
                <div className='w-full overflow-auto mt-4'>
                    <table className='w-full text-center'>
                        <tr>
                            <th className='py-3 px-2'>Type</th>
                            <th className='py-3 px-2'>Name</th>
                            <th className='py-3 px-2'>Details</th>
                            <th className='py-3 px-2'></th>
                        </tr>
                        {
                            rooms.length > 0 && rooms.map((r, i) => (
                                <tr key={i} className='border-t hover:bg-slate-100'>
                                    <td className='py-3 px-2'>{r.type}</td>
                                    <td className='py-3 px-2'>{r.name}</td>
                                    <td className='py-3 px-2'>{r.details}</td>
                                    <td className='flex justify-center items-center gap-4 py-3 px-2'>
                                        {
                                            loading ? (
                                                <RiLoader5Line className='animate-spin' />
                                            ) : (
                                                <>
                                                    <button title="Edit Room" onClick={_ => editRoom(r.id)}>
                                                        <CiEdit className='text-xl' />
                                                    </button>
                                                    <button title="Delete Room" onClick={_ => deleteRoom(r.id)}>
                                                        <CiTrash className='text-xl' />
                                                    </button>
                                                </>
                                            )
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </table>
                </div>
                <hr className='mt-4' />
                <button className='mt-4 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center' onClick={saveData} disabled={loading}>
                    Save
                    {
                        loading && (
                            <RiLoader5Line className='animate-spin' />
                        )
                    }
                </button>
            </div>
            <Modal onShow={roomModal} onHide={roomModalF} mainBtn={update ? 'Update Room' : 'Add Room'} onClick={update ? updateRoom : addRoom} mainBtnDisabled={loading}>
                <div className='pt-4'>
                    <label htmlFor="type">Room Type</label>
                    <select
                        id="type"
                        name="type"
                        className={Inputstyle}
                        value={singleRoom?.type}
                        onChange={e => setSingleRoom({ ...singleRoom, type: e.target.value })}
                    >
                        <option value="">-- Select type --</option>
                        <option value="Kitchen">Kitchen</option>
                        <option value="Bedroom">Bedroom</option>
                        <option value="Bathroom">Bathroom</option>
                        <option value="Utility Room">Utility Room</option>
                        <option value="External">External</option>
                        <option value="Living Room">Living Room</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className='pt-4'>
                    <label htmlFor="name2">Rooms Name</label>
                    <input
                        type="text"
                        id="name2"
                        name="name2"
                        className={Inputstyle}
                        value={singleRoom?.name}
                        onChange={e => setSingleRoom({ ...singleRoom, name: e.target.value })}
                    />
                </div>
                <div className='pt-4'>
                    <label htmlFor="details">Rooms</label>
                    <textarea
                        id="details"
                        name="details"
                        className={Inputstyle}
                        value={singleRoom?.details}
                        onChange={e => setSingleRoom({ ...singleRoom, details: e.target.value })}
                        rows={4}
                    >
                    </textarea>
                </div>
            </Modal>
        </>
    )
}
