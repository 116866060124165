import { useEffect, useState } from 'react';
import AdminAuth from './AdminContext';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { tokenVerify } from '../../dashboard/libs/apis';

const adminObj = { email: '', id: '', role: ''}

export default function AdminAuthProvider({ children }) {
  const [admin, setAdmin] = useState(adminObj)
  const [token, setToken] = useState({})
  const [loginStatus, setLoginStatus] = useState(false)
  
  const navigate = useNavigate();

  
  useEffect(()=>{
    const tokenVeri = async () => {
      const tokenLocal = JSON.parse(localStorage.getItem('token'))
      let res = await tokenVerify(tokenLocal?.token)
      if (tokenLocal?.status) {
        if(res?.status){
          setToken(tokenLocal)
          const decodeData = jwtDecode(tokenLocal.token)
          setLoginStatus(true)
          setAdmin({ email: decodeData?.email, id: decodeData?.uniqueId, role: decodeData?.role })
        } else {
          setLoginStatus(false)
          setAdmin(adminObj)
          navigate('/dashboard/login');
        }
      } else {
        setLoginStatus(false)
        setAdmin(adminObj)
        navigate('/dashboard/login');
      }
    }
    tokenVeri()
  },[setAdmin, setToken, setLoginStatus, navigate])


  return (
    <AdminAuth.Provider value={{admin, setAdmin, loginStatus, setLoginStatus, token, setToken}}>
      { children }
    </AdminAuth.Provider>
  );
}