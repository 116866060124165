import React, { useContext, useEffect, useState } from 'react'
import DashboardLayout from './DashboardLayout'
import Button from '../UI/Button';
import { HiOutlineCog, HiOutlineEye, HiOutlineEyeOff, HiOutlinePencil, HiOutlineTrash, HiOutlineX } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import Table from '../UI/Table';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import toast from 'react-hot-toast';
import { AiOutlineLoading } from "react-icons/ai";
import { createUserApi, deleteUserApi, getUserApi, updateUserApi, updateUserPasswordApi } from './libs/apis';
import AdminAuth from '../Context/AdminAuth/AdminContext';
import StatusSwitch from './Components/StatusSwitch';

export default function Users() {
  return(
    <DashboardLayout>
      <UsersPage/>
    </DashboardLayout>
  )
}

const createUserObj = {
  firstName:{
    error:'Enter user first name.',
    value:''
  },
  lastName:{
    error:'Enter user last name.',
    value:''
  },
  email:{
    error:'Enter user email.',
    value:''
  },
  password:{
    error:'Create user password.',
    value:''
  },
  role:{
    error:'Select user role.',
    value:''
  },
  phone:'',
  address:''
}

const updateUserObj = {
  firstName:'',
  lastName:'',
  email:'',
  role:'',
  phone:''
  ,address:''
}

export function UsersPage() {
  const [createModal, setCreateModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)
  const [createUserField, setCreateUserField] = useState(createUserObj)
  const [seePassword, setSeePassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState(null)
  const [filter, setFilter] = useState(null)
  const [updateUser, setUpdateUser] = useState(updateUserObj);
  const [passwordUser, setPasswordUser] = useState(null)
 

  // get admin credentials
  const {admin} = useContext(AdminAuth)

  const createModalF = () => {
    setCreateModal(false)
    setCreateUserField(createUserObj)
  }

  const updateModalF = () => {
    setUpdateModal(false)
    setUpdateUser(null)
  }

  const passwordModalF = () => {
    setPasswordModal(false)
    setPasswordUser(null)
  }

  // Validator
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // Password Generator
  const generatePassword = (inp) => {
    var length = 12,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    if(inp === 1 ){
      setCreateUserField({...createUserField, password: {...createUserField.password, value:retVal}})
    } else {
      setPasswordUser({...passwordUser, password: retVal})
    }
  }

  // See Password Function
  const seePasswordF = () =>{
    setSeePassword(!seePassword)
  }

  // Create User
  const createUser = async () => {
    if(createUserField.firstName.value === '' || createUserField.lastName.value === '' || createUserField.email.value === '' || createUserField.password.value === '' || createUserField.role.value === '') {
      if (createUserField.firstName.value === '') {
        toast.error(createUserField.firstName.error,{
          duration:5000,
          position: 'bottom-center',
        })
      } else {
        if (createUserField.lastName.value === '') {
          toast.error(createUserField.lastName.error,{
            duration:5000,
            position: 'bottom-center',
          })
        } else {
          if (createUserField.email.value === '') {
            toast.error(createUserField.email.error,{
              duration:5000,
              position: 'bottom-center',
            })
          } else {
            if (createUserField.password.value === '') {
              toast.error(createUserField.password.error,{
                duration:5000,
                position: 'bottom-center',
              })
            } else {
              if (createUserField.role.value === '') {
                toast.error(createUserField.role.error,{
                  duration:5000,
                  position: 'bottom-center',
                })
              } 
            }
          }
        }
      }

    } else {
      if(validateEmail(createUserField?.email?.value)) {
        const data = {
          adminUniqueId: admin?.id,
          adminRole: admin?.role,
          firstName: createUserField?.firstName?.value,
          lastName: createUserField?.lastName?.value,
          email: createUserField?.email?.value,
          password: createUserField?.password?.value,
          role: createUserField?.role?.value,
          phone: createUserField?.phone,
          address: createUserField?.address
        }
        setLoading(true)
        const res = await createUserApi(data);
        if(res?.status) {
          toast.dismiss();
          toast.success(res?.message,{
            duration:5000,
            position: 'bottom-center',
          })
          await getUsers(admin, filter)
          setCreateModal(false)
          setLoading(false)
          setCreateUserField(createUserObj)
        } else {
          toast.dismiss();
          toast.error(res?.message,{
            duration:5000,
            position: 'bottom-center',
          })
          setLoading(false)
        }
      } else {
        toast.dismiss();
        toast.error('Enter valid user email.',{
          duration:5000,
          position: 'bottom-center',
        })
        setLoading(false)
      }
    }
  }

  // get All user and by filter
  const getUsers = async (admin, filter) => {
    const res = await getUserApi(admin?.id, admin?.role, filter)
    console.log(res);
    if(res?.length > 0){
      setUsers(res)
    } else {
      setUsers(null)
    }
  }

  useEffect(()=>{
    getUsers(admin, filter)
  },[admin, filter])


  // Update User
  const updateUserF = async () => {
    const data = {
      ...updateUser,
      adminUniqueId: admin?.id,
      adminRole: admin?.role,
    }
    setLoading(true)
    let res = await updateUserApi(data)
    if(res?.status) {
      toast.dismiss();
      toast.success(res?.message,{
        duration:5000,
        position: 'bottom-center',
      })
      await getUsers(admin, filter)
      setUpdateModal(false)
      setLoading(false)
      setUpdateUser(null)
    } else {
      toast.dismiss();
      toast.error(res?.message,{
        duration:5000,
        position: 'bottom-center',
      })
      setLoading(false)
    }
  }

  // Update Password
  const passwordUserF = async () => {
    if(passwordUser.password) {
      let data = {
        ...passwordUser,
        adminUniqueId: admin?.id,
        adminRole: admin?.role,
      }
      setLoading(true)
      let res = await updateUserPasswordApi(data)
      if(res?.status) {
        toast.dismiss();
        toast.success(res?.message,{
          duration:5000,
          position: 'bottom-center',
        })
        setUpdateModal(false)
        setLoading(false)
        setUpdateUser(null)
      } else {
        toast.dismiss();
        toast.error(res?.message,{
          duration:5000,
          position: 'bottom-center',
        })
        setLoading(false)
      }
    } else {
      toast.dismiss();
      toast.error('Create password first.',{
        duration:5000,
        position: 'bottom-center',
      })
    }
  }

  // Delete User 
  const deleteUser = async (id) => {
    let con = window.confirm('Are you sure?');
    if(con) {
      setLoading(true)
      let res = await deleteUserApi({uniqueId: id, adminUniqueId: admin?.id, adminRole: admin?.role})
      if(res?.status) {
        toast.dismiss();
        toast.success(res?.message,{
          duration:5000,
          position: 'bottom-center',
        })
        await getUsers(admin, filter)
        setLoading(false)
      } else {
        toast.dismiss();
        toast.error(res?.message,{
          duration:5000,
          position: 'bottom-center',
        })
        setLoading(false)
      }
    }
  }
  
  

  // Change Page Title
  useEffect(() => {
    document.title = 'Users - SA Assistance';
  }, [])

  return (
    <>
      <div className='w-full mb-5'>
        <Button type='button' size='small' text="Create Users" onClick={_=> setCreateModal(true)}/>
      </div>
      <div className='w-full p-4 lg:p-7 bg-white shadow-lg border-2 border-slate-100 rounded-lg'>
        <h3 className='text-lg font-medium mb-2'>filter:</h3>
        {/* Filters */}
        <div className='flex gap-3 mb-2 sm:mb-4 overflow-auto pb-3 sm:pb-4 px-2 sm:p-0'>
          {filter && <Button 
            text={<HiOutlineX className='text-gray-600'/>} 
            size="small" 
            onClick={_ => {
              setUsers(null)
              setFilter(null)
            }} 
            className="text-lg hover:bg-slate-400 bg-slate-300 "/>}
          <Button 
            text="Introducer" 
            size="small" 
            onClick={_ => {
              setUsers(null)
              setFilter(22)
            }} 
            className={filter === 22 ? "" : "bg-slate-400 hover:bg-slate-500"}/>
          <Button 
            text="Solicitor"
            size="small"
            onClick={_ => {
              setUsers(null)
              setFilter(33)
            }} 
            className={filter === 33 ? "" : "bg-slate-400 hover:bg-slate-500"}/>
          <Button 
            text="Manager" 
            size="small" 
            onClick={_ => {
              setUsers(null)
              setFilter(39)
            }} 
            className={filter === 39 ? "" : "bg-slate-400 hover:bg-slate-500"}/>
        </div>
        <Table heading={['#','Name', 'Email', 'Matters', 'role', 'Status', 'Actions']}>
          { !users?.length > 0 && (
            <tr>
              <td colSpan="6" className='py-3'>
                <AiOutlineLoading className='animate-spin text-2xl mx-auto'/>
              </td>
            </tr>)
          }
          {
            users?.length > 0 && users?.map(u => {
              let bg = 'bg-slate-400'
              if(u?.role === 33) {
                bg = 'bg-green-400'
              } else if(u?.role === 39) {
                bg = 'bg-yellow-400'
              }
              return(
                <tr className='border-b-[1px] hover:bg-slate-100 transition-all duration-300' key={u?.id}>
                  <td className='p-3 min-w-[100px]'>{u?.uniqueId}</td>
                  <td className='p-3 min-w-[100px]'>{u?.firstName + ' ' + u?.lastName}</td>
                  <td className='p-3 min-w-[100px]'>{u?.email}</td>
                  <td className='p-3 min-w-[100px]'>{u?.mattersCount}</td>
                  <td className='p-3 min-w-[100px]'>
                    <span className={bg + ` px-2 py-1 rounded-md font-bold text-gray-700 text-sm`}>
                      {u?.role === 22 && 'Introducer'}
                      {u?.role === 33 && 'Solicitor'}
                      {u?.role === 39 && 'Manager'}
                    </span>
                  </td>
                  <td>
                    <StatusSwitch id={u?.id} status={u?.status} admin={admin}/>
                  </td>
                  <td className='p-3 min-w-[100px]'>
                    <div className='flex gap-4 justify-center'>
                      <Link 
                        onClick={_=>{
                          setUpdateUser(u)
                          setUpdateModal(true)
                        }}
                        className='inline hover:opacity-70'>
                        <HiOutlinePencil />
                      </Link>
                      <Link to="" className='inline hover:opacity-70' onClick={_=> deleteUser(u?.uniqueId)}>
                        {loading ? <AiOutlineLoading className='animate-spin' /> : <HiOutlineTrash />}
                      </Link>
                      <Link
                        onClick={_=> {
                          setPasswordModal(true)
                          setPasswordUser({uniqueId: u?.uniqueId})
                        }}
                        className='inline hover:opacity-70'
                        >
                        <HiOutlineCog />
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            })
          }
        </Table>
      </div>
      {/* Create User */}
      <Modal onShow={createModal} onHide={createModalF} closeBtn="Nevermind" mainBtn={loading ? <AiOutlineLoading className='animate-spin' /> : 'Create'} onClick={createUser} mainBtnDisabled={loading}>
        <div className='grid sm:grid-cols-2 gap-3 sm:gap-5 mb-3 sm:mb-5'>
          <Input type="text" placeholder="First Name" value={createUserField?.firstName?.value} onChange={(e) => setCreateUserField({...createUserField, firstName:{...createUserField.firstName, value: e.target.value}})}/>
          <Input type="text" placeholder="Last Name" value={createUserField?.lastName?.value} onChange={(e) => setCreateUserField({...createUserField, lastName:{...createUserField.lastName, value: e.target.value}})}/>
        </div>
        <Input type="email" placeholder="User Email" required={true} className="mb-3 sm:mb-5" value={createUserField?.email?.value} onChange={(e) => setCreateUserField({...createUserField, email:{...createUserField.email, value: e.target.value}})}/>
        <div className='flex gap-3 sm:gap-5 mb-3 sm:mb-5'>
          <Input type={seePassword ? 'text': 'password'} placeholder="Password" required={true} value={createUserField?.password?.value} onChange={(e) => setCreateUserField({...createUserField, password:{...createUserField.password, value: e.target.value}})}/>
          <Button type="button" text={seePassword ? <HiOutlineEyeOff className='text-gray-600'/> : <HiOutlineEye className='text-gray-600'/>} size="big" onClick={seePasswordF} className="bg-slate-200 hover:bg-slate-300"/>
          <Button type="button" text="Password" size="big" onClick={_=>generatePassword(1)}/>
        </div>
        <select value={createUserField?.role?.value} onChange={(e) => setCreateUserField({...createUserField, role:{...createUserField.role, value: e.target.value}})} className='mb-3 sm:mb-5 py-2 px-4 md:py-3 md:px-5 w-full border-2 rounded-lg text-md md:text-lg outline-white focus:outline-purple-300 transition-all duration-300' required>
          <option className='text-gray-200'>Select User Role</option>
          <option value="22">Introducer</option>
          <option value="33">Solicitor</option>
          <option value="39">Manager</option>
        </select>
        <Input type="phone" placeholder="User Phone" className="mb-3 sm:mb-5" value={createUserField?.phone} onChange={(e) => setCreateUserField({...createUserField, phone: e.target.value})}/>
        <Input type="text" placeholder="User Address" value={createUserField?.address} onChange={(e) => setCreateUserField({...createUserField, address:e.target.value})}/>
      </Modal>
      {/* Update USer */}
      <Modal onShow={updateModal} onHide={updateModalF} closeBtn="Nevermind" mainBtn={loading ? <AiOutlineLoading className='animate-spin' /> : 'Update'} onClick={updateUserF} mainBtnDisabled={loading}>
        <div className='grid sm:grid-cols-2 gap-3 sm:gap-5 mb-3 sm:mb-5'>
          <Input type="text" placeholder="First Name" value={updateUser?.firstName} onChange={(e) => setUpdateUser({...updateUser, firstName:e.target.value})}/>
          <Input type="text" placeholder="Last Name" value={updateUser?.lastName} onChange={(e) => setUpdateUser({...updateUser, lastName:e.target.value})}/>
        </div>
        <Input type="email" readOnly placeholder="User Email" required className="mb-3 sm:mb-5" value={updateUser?.email} onChange={(e) => setUpdateUser({...updateUser, email:e.target.value})}/>
        <select value={updateUser?.role} onChange={(e) => setUpdateUser({...updateUser, role:e.target.value})} className='mb-3 sm:mb-5 py-3 px-5 w-full border-2 rounded-lg text-lg outline-white focus:outline-purple-300 transition-all duration-300' required>
          <option className='text-gray-200'>Select User Role</option>
          <option value="22">Introducer</option>
          <option value="33">Solicitor</option>
          <option value="39">Manager</option>
        </select>
        <Input type="phone" placeholder="User Phone" className="mb-3 sm:mb-5" value={updateUser?.phone} onChange={(e) => setUpdateUser({...updateUser, phone: e.target.value})}/>
        <Input type="text" placeholder="User Address" value={updateUser?.address} onChange={(e) => setUpdateUser({...updateUser, address:e.target.value})}/>
      </Modal>
      {/* Change USer password */}
      <Modal onShow={passwordModal} onHide={passwordModalF} closeBtn="Nevermind" mainBtn={loading ? <AiOutlineLoading className='animate-spin' /> : 'Update'} onClick={passwordUserF} mainBtnDisabled={loading}>
          <Input type={seePassword ? 'text': 'password'} className="mb-3 sm:mb-5" placeholder="Password" required={true} value={passwordUser?.password} onChange={(e) => setPasswordUser({...passwordUser, password: e.target.value})}/>
          <div className='flex gap-3 sm:gap-5 mb-3 sm:mb-5'>
          <Button type="button" text={seePassword ? <HiOutlineEyeOff className='text-gray-600'/> : <HiOutlineEye className='text-gray-600'/>} size="big" onClick={seePasswordF} className="bg-slate-200 hover:bg-slate-300"/>
          <Button type="button" text="Password" size="big" onClick={generatePassword}/>
        </div>
      </Modal>
    </>
  )
}
