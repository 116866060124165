import React, { useContext, useState } from 'react'
import UserLayout from '../UserLayout'
import Button from '../UI/Button'
import { createMetterApi } from '../libs/apis'
import UserAuth from '../Context/UserAuth/UserContext'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'


export default function CreateMatter() {
  return (
    <UserLayout>
      <CreateMatterPage />
    </UserLayout>
  )
}

export function CreateMatterPage() {
  const [loading, setLoading] = useState(false)
  let Inputstyle = 'mt-2 py-2 px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '
  const {user} = useContext(UserAuth)
  const navigate = useNavigate()

  // create matter
  const createMatterForm = async (e) => {
    e.preventDefault()
    setLoading(true)
    toast.dismiss();
    toast.loading('Loading...',{
      duration:5000,
      position: 'bottom-center',
    })

    let formData = new FormData(e.target);
    let obj = Object.fromEntries(formData.entries())
    obj = {...obj, userId: user?.id}

    let res = await createMetterApi(obj);
    if(res?.status) {
      toast.dismiss();
      toast.success(res?.message,{
        duration:5000,
        position: 'bottom-center',
      })
      navigate(`/matter/${res?.matterId}/edit`)
      setLoading(true)
    } else {
      toast.dismiss();
      toast.error(res?.message,{
        duration:5000,
        position: 'bottom-center',
      })
      setLoading(false)
    }
  }

  if(user?.role === 39){
    navigate(`/`)
  }

  return (
    <>
      <div className='text-2xl font-semibold mb-5'>Create Lead</div>
      <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-10'>
        <form onSubmit={createMatterForm}>
          <div className='w-full'>
            <label htmlFor="type" className='text-md' required>MatterType *</label>
            <select name="type" id="type" className={Inputstyle}>
              <option >--Please Select--</option>
              <option value="1" selected>Housing Disrepair</option>
            </select>
          </div>
          <div className='w-full mt-4'>
            <label htmlFor="title" className='text-md' >Title *</label>
            <select name="title" id="title" className={Inputstyle} required>
              <option >--Please Select--</option>
              <option value="Mrs">Mrs</option>
              <option value="Mr">Mr</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Mx">Mx</option>
            </select>
          </div>
          <div className='w-full mt-4'>
            <label htmlFor="firstName" className='text-md' >First Name *</label>
            <input type="text" name='first_name' id='firstName' className={Inputstyle} placeholder='Enter client first name' required />
          </div>
          <div className='w-full mt-4'>
            <label htmlFor="lastName" className='text-md' >Last Name *</label>
            <input type="text" id='lastName' name='last_name' className={Inputstyle} placeholder='Enter client last name' required />
          </div>
          <div className='w-full mt-4'>
            <label htmlFor="phone" className='text-md' >Telephone *</label>
            <input type="text" id='phone' name='phone' className={Inputstyle} placeholder='Enter client telephone' required />
          </div>
          <Button type='submit' text='Create' size='big' className='mt-4' disabled={loading}/>
        </form>
      </div>
    </>
  )
}