import React, { useEffect } from 'react'
import DashboardLayout from './DashboardLayout'

export default function MattersAdmin() {
    // Change Page Title
    useEffect(() => {
        document.title = 'Matters - SA Assistance';
    }, [])
    return (
        <DashboardLayout>
            <div>Matters</div>
        </DashboardLayout>
    )
}
