import React, { useState, useContext } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import AdminAuthProvider from '../Context/AdminAuth/AdminAuthProvieder';
import AdminAuth from '../Context/AdminAuth/AdminContext';
import Input from '../UI/Input';
import Button from '../UI/Button';
import { useNavigate } from 'react-router-dom';
import { sendEmailCode, verifyCode } from './libs/apis';

const bgs = [
  'bg-login-image-1.jpg',
  'bg-login-image-2.jpg',
  'bg-login-image-3.jpg',
  'bg-login-image-4.jpg',
  'bg-login-image-5.jpg',
]
const index = Math.floor(Math.random() * 5);
let style = {
  backgroundImage:`url("/img/${bgs[index]}")`, 
  backgroundPosition:'center center', 
  backgroundRepeat:'no-repear', 
  backgroundSize:'cover'
}

const fieldObj = {email: '', code: ''}

document.title = 'SA Assistance';

export default function Login() {

  return(
    <AdminAuthProvider>
      <LoginForm/>
    </AdminAuthProvider>
  )

}

export function LoginForm() {
  const [inp, setInp] = useState(false)
  const [field, setField] = useState(fieldObj)
  const [loading, setLoading] = useState(false)

  const {setToken, loginStatus} = useContext(AdminAuth)
  const navigate = useNavigate();

  if(loginStatus){
    navigate('/dashboard')
    return;
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  //Send Verification Code to the Admin
  const sendCode = async () => {
    if(validateEmail(field?.email)) {
      toast.loading('Loading...',{
        position: 'bottom-center',
      });
      setLoading(true)
      const res = await sendEmailCode(field?.email);
      if(res?.status){
        toast.dismiss();
        toast.success(res?.message,{
          duration:5000,
          position: 'bottom-center',
        })
        setInp(true)
        setLoading(false)
      } else {
        toast.dismiss();
        toast.error(res?.message,{
          duration:5000,
          position: 'bottom-center',
        })
        setLoading(false)
      }

    } else {
      toast.dismiss();
      toast.error('Enter valid email.',{
        duration:5000,
        position: 'bottom-center',
      })
      setLoading(false)
    }
  }

  //Admin login
  const login = async () => {
    if(field.code){
      setLoading(true)
      toast.loading('Loading...',{
        position: 'bottom-center',
      });

      let res = await verifyCode(field?.email, field?.code)

      if(res?.status) {
        toast.dismiss();
        toast.success(res?.message,{
          duration:5000,
          position: 'bottom-center',
        })
        setTimeout(()=>{
          navigate('/dashboard')
        },5000)
        localStorage.setItem('token', JSON.stringify(res))
        setInp(false)
        setField(fieldObj)
        setToken(res)
        setLoading(true)
      } else {
        toast.dismiss();
        toast.error(res?.message,{
          duration:5000,
          position: 'bottom-center',
        })
        setLoading(false)
      }
      
    } else {
      toast.dismiss();
      toast.error('Enter verification code.',{
        duration:5000,
        position: 'bottom-center',
      })
      setLoading(false)
    }
  }

  return (
      <div className='flex'>
        <div className='xl:w-1/3 lg:w-1/2 w-full h-screen bg-purple-900 lg:p-20 p-10 flex items-center justify-center'>
          <div className='bg-white min-h-[200px] w-full md:w-1/2 lg:w-full rounded-lg p-6 shadow-md text-center'>
            <h2 className='text-2xl font-bold'>Admin</h2>
            <form onSubmit={(e)=> e.preventDefault()}>
              <Input 
                type="email" 
                placeholder='Enter admin email'
                value={field?.email}
                onChange={(e)=> setField({...field, email: e.target.value})}
                className="mt-6 my-3"
                required={true}/>
              {inp && <Input 
                type="text" 
                placeholder='Enter verification code' 
                value={field?.code}
                onChange={(e)=> setField({...field, code: e.target.value})}
                className="mb-3"
                required/>}
              {
                inp ? (
                    <Button type='button' onClick={login} text='Login' size="big" block={true} disabled={loading}/>
                  ) : (
                    <Button type='button' onClick={sendCode} text="Send Verification Code" size="big" block={true} disabled={loading}/>
                )
              }
            </form>
          </div>
        </div>
        <div className="xl:w-2/3 lg:w-1/2 h-screen md:block hidden" style={style}></div>
        <Toaster/>
      </div>
  )
}
