const apiDomain = process.env.REACT_APP_API_DOMAIN

export const userLoginApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/users/userLogin.php`, {
        method: 'POST',
        body: JSON.stringify({ email: data.email, password: data.password })
    })
    const json = await res.json()
    return json;
} 

export const userVerificationApi = async (token) => {
    const res = await fetch(`${apiDomain}/api/users/userTokenVerify.php`, {
        method: 'POST',
        body: JSON.stringify({ token })
    })
    const json = await res.json()
    return json;
} 


export const userPasswordChange = async ({password, uniqueId}) => {
    const res = await fetch(`${apiDomain}/api/users/changePassword.php`, {
        method: 'PUT',
        body: JSON.stringify({ password, uniqueId })
    })
    const json = await res.json()
    return json;
} 

export const createMetterApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/users/createMatter.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getMettersApi = async (userId, role, status, stage, startDate, endDate, page, search) => {
    const res = await fetch(`${apiDomain}/api/users/getMatters.php`, {
        method: 'POST',
        body: JSON.stringify({userId, role, status, stage, startDate, endDate, page, reference: search})
    })
    const json = await res.json()
    return json;
} 

export const getClientApi = async ({userId, role, matterId}) => {
    const res = await fetch(`${apiDomain}/api/matters/getClientById.php`, {
        method: 'POST',
        body: JSON.stringify({userId, role, matterId})
    })
    const json = await res.json()
    return json;
} 

export const saveClientDataApi = async ({userId, role, matterId, data}) => {
    const res = await fetch(`${apiDomain}/api/matters/saveClientData.php`, {
        method: 'POST',
        body: JSON.stringify({userId, role, matterId, data})
    })
    const json = await res.json()
    return json;
} 


export const saveLandlordApi = async ({userId, role, matterId, data}) => {
    const res = await fetch(`${apiDomain}/api/matters/saveLandlord.php`, {
        method: 'POST',
        body: JSON.stringify({userId, role, matterId, name: data})
    })
    const json = await res.json()
    return json;
} 

export const getLandlordApi = async ({userId, role, matterId}) => {
    const res = await fetch(`${apiDomain}/api/matters/getLandlord.php`, {
        method: 'POST',
        body: JSON.stringify({userId, role, matterId})
    })
    const json = await res.json()
    return json;
} 

export const saveFilesApi = async (formData) => {
    const res = await fetch(`${apiDomain}/api/matters/saveFiles.php`, {
        method: 'POST',
        body: formData
    })
    const json = await res.json()
    return json;
} 

export const getFilesApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getFiles.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const deleteFileApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/deleteFile.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const imageFetcher = async (url) => {
    const res = await fetch(`${apiDomain}/api/imageFetcher.php?url=${url}`, {
        method: 'GET',
    })
    const blob = await res.blob()
    return blob;
} 


export const saveTenancyDetails = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/saveHousinfDisrepairs.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getHousingDisrepairApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getHousingDisrepair.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getPropertyDetailsApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getPropertyDetails.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const savePropertyDetailsApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/savePropertyDetails.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getClaimApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getClaim.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const saveClaimApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/saveClaim.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getRoomsApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getRooms.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const saveRoomsApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/saveRooms.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getComplaintsApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getComplaints.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const saveComplaintsApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/saveComplaints.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getPersonalInquiryApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getPersonalInquiry.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const savePersonalInquiryApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/savePersonalInquiry.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getMatterOneApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getMatter.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const saveStatusApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/saveStatus.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getTimelineApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getTImelines.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 


export const getNotesApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getNote.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const saveNotesApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/saveNote.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const saveSolictorApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/saveSolictor.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const getReportApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getReport.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 


export const getNotificationApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/getNotification.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const countNotificationApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/countNotification.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 

export const updateNotificationApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/matters/updateNotification.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
} 
