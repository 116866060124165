import React, { useEffect, useState } from 'react'
import { IoTrashOutline } from "react-icons/io5";
import Client from 'getaddress-api'
import Modal from '../../UI/Modal';
import { saveClientDataApi } from '../../libs/apis';
import toast from 'react-hot-toast';
import { RiLoader5Line } from "react-icons/ri";


let addressObj = { addressline_1: '', addressline_2: '', addressline_3: '', addressline_4: '', townOrCity: '', postcode: '' }

export default function ClientForm({ data, user }) {
    const [clientData, setClientData] = useState({})
    const [addressModal, setAddressModal] = useState(false)
    const [addressModalBtn, setAddressModalBtn] = useState(true)
    const [address, setAddress] = useState(addressObj)
    const [postcode, setPostcode] = useState('')
    const [getAddresses, setGetAddresses] = useState(null)
    const [loading, setLoading] = useState(false)

    const api = new Client(process.env.REACT_APP_GETADDRESSAPI);

    useEffect(() => {
        setClientData(data)
    }, [data])

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md md:text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '

    // Emails

    const addEmail = () => {
        let emails = clientData.emails
        let email = { email: '', primary_email: 0 }
        if (emails.length > 0) {
            email.primary_email = 0
        } else {
            email.primary_email = 1
        }
        setClientData({ ...clientData, emails: [...clientData.emails, email] })
    }

    const updateEmail = (e, i) => {
        let emails = clientData.emails
        emails[i].email = e.target.value;
        setClientData({ ...clientData, emails })
    }

    const deleteEmail = (ind) => {
        let arr = clientData.emails
        arr = arr.filter((e, i) => ind !== i && e)
        setClientData({ ...clientData, emails: arr })
    }

    const primaryEmail = (ind) => {
        let arr = clientData.emails
        arr = arr.map((e, i) => ind === i ? { ...e, primary_email: 1 } : { ...e, primary_email: 0 })
        setClientData({ ...clientData, emails: arr })
    }

    // Phones

    const addPhone = () => {

        let phones = clientData.phones
        let phone = { phone: '', ext: '', primary_number: 0 }
        if (phones.length > 0) {
            phone.primary_number = 0
        } else {
            phone.primary_number = 1
        }
        setClientData({ ...clientData, phones: [...clientData.phones, phone] })
    }

    const updatePhone = (e, i) => {
        if (e.target.name === 'phone[]') {
            let phones = clientData.phones
            phones[i].phone = e.target.value;
            setClientData({ ...clientData, phones })
        } else {
            let phones = clientData.phones
            phones[i].ext = e.target.value;
            setClientData({ ...clientData, phones })
        }
    }

    const deletePhone = (ind) => {
        let arr = clientData.phones
        arr = arr.filter((e, i) => ind !== i && e)
        setClientData({ ...clientData, phones: arr })
    }

    const primaryNumber = (ind) => {
        let arr = clientData.phones
        arr = arr.map((e, i) => ind === i ? { ...e, primary_number: 1 } : { ...e, primary_number: 0 })
        setClientData({ ...clientData, phones: arr })
    }

    // Address

    const addAddress = () => {
        setClientData({...clientData, addresses: [...clientData.addresses, address]})
        setAddress(addressObj)
        setAddressModal(false)
        setGetAddresses(null)
        setAddressModalBtn(true)
    }

    const deleteAddress = (ind) => {
        let arr = clientData.addresses
        arr = arr.filter((e, i) => ind !== i && e)
        setClientData({ ...clientData, addresses: arr })
    }

    // get Address by api
    const getAddress = async () => {
        const findResult = await api.find(postcode);

        if (findResult.isSuccess) {
            const success = findResult.toSuccess();
            setGetAddresses(success.addresses)
        }
        else {
            const failed = findResult.toFailed();
            console.log(failed);
        }
    }

    const addressModalF = () => {
        setAddressModal(!addressModal)
    }

    const putAddress = (i) => {
        let arr = { 
                addressline_1: getAddresses?.addresses[i].line_1, 
                addressline_2: getAddresses?.addresses[i].line_2, 
                addressline_3: getAddresses?.addresses[i].line_3, 
                addressline_4: getAddresses?.addresses[i].line_4, 
                townOrCity: getAddresses?.addresses[i].town_or_city, 
                postcode: postcode
            }
        setAddress(arr)
        setAddressModalBtn(false)
    }

    const updateAddress = (e, i) => {
        if (e.target.name === 'addressline_1') {
            let addresses = clientData.addresses
            addresses[i].addressline_1 = e.target.value;
            setClientData({ ...clientData, addresses })
            return
        } 
        if (e.target.name === 'addressline_2') {
            let addresses = clientData.addresses
            addresses[i].addressline_2 = e.target.value;
            setClientData({ ...clientData, addresses })
            return
        } 
        if (e.target.name === 'addressline_3') {
            let addresses = clientData.addresses
            addresses[i].addressline_3 = e.target.value;
            setClientData({ ...clientData, addresses })
            return
        } 
        if (e.target.name === 'addressline_4') {
            let addresses = clientData.addresses
            addresses[i].addressline_4 = e.target.value;
            setClientData({ ...clientData, addresses })
            return
        }
        if (e.target.name === 'townOrCity') {
            let addresses = clientData.addresses
            addresses[i].townOrCity = e.target.value;
            setClientData({ ...clientData, addresses })
            return
        } 
        if (e.target.name === 'postcode') {
            let addresses = clientData.addresses
            addresses[i].postcode = e.target.value;
            setClientData({ ...clientData, addresses })
            return
        } 
    }


    // saveData

    const saveData = async () => {
        toast.loading('Loading...',{
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveClientDataApi({userId:user.id, role: user.role, matterId: clientData.matterId, data: clientData })
        if(res?.status) {
            toast.dismiss();
            toast.success('Client record has been updated.',{
              duration:5000,
              position: 'bottom-center',
            })
            setClientData(res?.data)
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message,{
              duration:5000,
              position: 'bottom-center',
            })
            setLoading(false)
        }
    }

    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7'>
                <h3 className='text-lg pb-3 font-semibold'>Clients</h3>
                <hr />
                <div className='flex gap-3 pt-4 flex-wrap md:flex-nowrap'>
                    <div className='w-full md:max-w-[250px]'>
                        <label htmlFor="title">Title</label>
                        <select
                            name="title"
                            id="title"
                            className={Inputstyle}
                            value={clientData?.title}
                            onChange={e => setClientData({ ...clientData, title: e.target.value })}
                        >
                            <option >--Please Select--</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Mr">Mr</option>
                            <option value="Miss">Miss</option>
                            <option value="Ms">Ms</option>
                            <option value="Mx">Mx</option>
                        </select>
                    </div>
                    <div className='w-full'>
                        <label htmlFor="fName">First Name</label>
                        <input
                            type="text"
                            id="fName"
                            name="first_name"
                            className={Inputstyle}
                            value={clientData?.first_name}
                            onChange={e => setClientData({ ...clientData, first_name: e.target.value })}
                        />
                    </div>
                    <div className='w-full'>
                        <label htmlFor="lName">Last Name</label>
                        <input
                            type="text"
                            id="lName"
                            name="last_name"
                            className={Inputstyle}
                            value={clientData?.last_name}
                            onChange={e => setClientData({ ...clientData, last_name: e.target.value })}
                        />
                    </div>
                </div>
                <div className='pt-4'>
                    <label htmlFor="date">Date of Birth</label>
                    <input
                        type="date"
                        id="date"
                        name="dob"
                        className={Inputstyle}
                        value={clientData?.dob}
                        onChange={e => setClientData({ ...clientData, dob: e.target.value })}
                    />
                </div>
                {/* Emails */}
                <div className='border border-slate-200 rounded-lg p-3 md:p-5 mt-4'>
                    <div className='flex justify-between items-center mb-2'>
                        <h3 className=' text-lg font-medium'>Email Addresses</h3>
                        <button
                            className='py-2 px-3 border border-slate-200 rounded-md hover:border-slate-400 transition-all duration-300 text-sm md:text-md'
                            onClick={addEmail}
                        >Add New Email</button>
                    </div>
                    <hr />
                    {
                        clientData?.emails?.length > 0 ? clientData?.emails.map((e, i) => (
                            <div className='flex gap-3 pt-3 items-end flex-wrap sm:flex-nowrap' key={i}>
                                <div className='w-full'>
                                    <label htmlFor="email">Email Address</label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email[]"
                                        className={Inputstyle}
                                        value={e?.email}
                                        onChange={e => updateEmail(e, i)}
                                    />
                                </div>
                                {clientData?.emails?.length > 1 && <button className='text-lg pb-3 px-2' title='Delete' onClick={_ => deleteEmail(i)}><IoTrashOutline /></button>}
                                {
                                    Number(e.primary_email) === 1 ? (
                                        <span className='px-3 py-1 mb-2 rounded-full bg-purple-800 text-white'>Primary</span>
                                    ) : (

                                        <button className='py-2 sm:py-3 sm:px-3 whitespace-pre' onClick={_ => primaryEmail(i)}>Make Primary</button>
                                    )
                                }
                            </div>
                        )) : (<p className="mt-4">No email address associated with this client.</p>)
                    }
                </div>
                {/* Phones */}
                <div className='border border-slate-200 rounded-lg p-3 md:p-5 mt-4'>
                    <div className='flex justify-between items-center mb-2'>
                        <h3 className=' text-lg font-medium'>Contact Numbers</h3>
                        <button
                            className='py-2 px-3 border border-slate-200 rounded-md hover:border-slate-400 transition-all duration-300 text-sm md:text-md'
                            onClick={addPhone}
                        >Add New Phone</button>
                    </div>
                    <hr />
                    {
                        clientData?.phones?.length > 0 ? clientData?.phones.map((e, i) => (
                            <div className='flex gap-3 pt-3 items-end flex-wrap md:flex-nowrap' key={i}>
                                <div className='w-full'>
                                    <label htmlFor="phone">Telephone</label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone[]"
                                        className={Inputstyle}
                                        value={e?.phone}
                                        onChange={e => updatePhone(e, i)}
                                    />
                                </div>
                                <div className='max-w-[150px]'>
                                    <label htmlFor="phone">Ext</label>
                                    <input
                                        type="text"
                                        id="ext"
                                        name="ext[]"
                                        className={Inputstyle}
                                        value={e?.ext}
                                        onChange={e => updatePhone(e, i)}
                                    />
                                </div>
                                {clientData?.phones?.length > 1 && <button className='text-lg pb-3 px-2' title='Delete' onClick={_ => deletePhone(i)}><IoTrashOutline /></button>}
                                {
                                    Number(e.primary_number) === 1 ? (
                                        <span className='px-3 py-1 mb-2 rounded-full bg-purple-800 text-white'>Primary</span>
                                    ) : (

                                        <button className='py-3 px-3 whitespace-pre' onClick={_ => primaryNumber(i)}>Make Primary</button>
                                    )
                                }
                            </div>
                        )) : (<p className="mt-4">No telephone number associated with this client.</p>)
                    }
                </div>
                {/* Address */}
                <div className='border border-slate-200 rounded-lg p-3 md:p-5 mt-4'>
                    <div className='flex justify-between items-center mb-2'>
                        <h3 className=' text-lg font-medium'>Addresses</h3>
                        <button
                            className='py-2 px-3 border border-slate-200 rounded-md hover:border-slate-400 transition-all duration-300 text-sm md:text-md'
                            onClick={addressModalF}
                        >Add New Addresses</button>
                    </div>
                    <hr />
                    {
                        clientData?.addresses?.length > 0 ? clientData?.addresses.map((e, i) => (
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-3 p-3 md:p-4 items-end border rounded-md mt-4' key={i}>
                                {
                                    e?.addressline_1 && (
                                        <div className='w-full'>
                                            <label htmlFor="add1">Address Line (1)</label>
                                            <input
                                                type="text"
                                                id="add1"
                                                name="addressline_1"
                                                className={Inputstyle}
                                                value={e?.addressline_1}
                                                onChange={e => updateAddress(e, i)}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    e?.addressline_2 && (
                                        <div className='w-full'>
                                            <label htmlFor="add2">Address Line (2)</label>
                                            <input
                                                type="text"
                                                id="add2"
                                                name="addressline_2"
                                                className={Inputstyle}
                                                value={e?.addressline_2}
                                                onChange={e => updateAddress(e, i)}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    e?.addressline_3 && (
                                        <div className='w-full'>
                                            <label htmlFor="add3">Address Line (3)</label>
                                            <input
                                                type="text"
                                                id="add3"
                                                name="addressline_3"
                                                className={Inputstyle}
                                                value={e?.addressline_3}
                                                onChange={e => updateAddress(e, i)}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    e?.addressline_4 && (
                                        <div className='w-full'>
                                            <label htmlFor="add4">Address Line (4)</label>
                                            <input
                                                type="text"
                                                id="add4"
                                                name="addressline_4"
                                                className={Inputstyle}
                                                value={e?.addressline_4}
                                                onChange={e => updateAddress(e, i)}
                                            />
                                        </div>
                                    )
                                }
                                <div className='w-full'>
                                    <label htmlFor="townOrCity">Town Or City</label>
                                    <input
                                        type="text"
                                        id="townOrCity"
                                        name="townOrCity"
                                        className={Inputstyle}
                                        value={e?.townOrCity}
                                        onChange={e => updateAddress(e, i)}
                                    />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="postcode">Postcode</label>
                                    <input
                                        type="text"
                                        id="postcode"
                                        name="postcode"
                                        className={Inputstyle}
                                        value={e?.postcode}
                                        onChange={e => updateAddress(e, i)}
                                    />
                                </div>
                                {clientData?.addresses?.length > 1 && <button className='max-w-[150px] text-lg py-2 px-4 inline-flex justify-center items-center gap-2 border border-slate-200 hover:border-slate-400 transition-all duration-300 rounded-md' title='Delete' onClick={_ => deleteAddress(i)}><IoTrashOutline /> Remove</button>}
                            </div>
                        )) : (<p className="mt-4">No addresses associated with this client.</p>)
                    }
                </div>
                <hr className='mt-4' />
                <button className='mt-4 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center' onClick={saveData} disabled={loading}>
                    Save
                    {
                        loading && (
                            <RiLoader5Line className='animate-spin'/>
                        )
                    }
                </button>
            </div>
            <Modal onShow={addressModal} onHide={addressModalF} mainBtn='Add Address' onClick={addAddress} mainBtnDisabled={addressModalBtn}>
                <div className='flex gap-3'>
                    <input
                        type="text"
                        name="findAddress"
                        placeholder='Postcode'
                        className={Inputstyle + '!mt-0'}
                        value={postcode}
                        onChange={e => setPostcode(e.target.value)}
                    />
                    <button
                        className='m-0 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-60'
                        onClick={getAddress}
                        disabled={postcode === ''}
                    >Find</button>
                </div>
                {
                    getAddresses?.addresses?.length > 0 && (
                        <select name="addresslist" className={Inputstyle+ ' mt-4'} onChange={e=>putAddress(e.target.value)}>
                            <option value="">--select address</option>
                            {
                                getAddresses.addresses?.map((a, i) => (
                                    <option value={i} key={i}>{a.formatted_address.toString().replace(/,/g, ' ')}</option>
                                ))
                            }
                        </select>
                    )
                }

                <div className='grid grid-cols-1 md:grid-cols-2 gap-3 p-3 md:p-4 items-end border rounded-md mt-4'>
                    <div className='w-full'>
                        <label htmlFor="add1">Address Line (1)</label>
                        <input
                            type="text"
                            id="add1"
                            name="addressline_1"
                            className={Inputstyle}
                            value={address.addressline_1}
                            onChange={e => setAddress({...address,addressline_1: e.target.value })}
                        />
                    </div>
                    <div className='w-full'>
                        <label htmlFor="add2">Address Line (2)</label>
                        <input
                            type="text"
                            id="add2"
                            name="addressline_2"
                            className={Inputstyle}
                            value={address.addressline_2}
                            onChange={e => setAddress({...address,addressline_2: e.target.value })}
                        />
                    </div>
                    <div className='w-full'>
                        <label htmlFor="add3">Address Line (3)</label>
                        <input
                            type="text"
                            id="add3"
                            name="addressline_3"
                            className={Inputstyle}
                            value={address.addressline_3}
                            onChange={e => setAddress({...address,addressline_3: e.target.value })}
                        />
                    </div>
                    <div className='w-full'>
                        <label htmlFor="add4">Address Line (4)</label>
                        <input
                            type="text"
                            id="add4"
                            name="addressline_4"
                            className={Inputstyle}
                            value={address.addressline_4}
                            onChange={e => setAddress({...address,addressline_4: e.target.value })}
                        />
                    </div>
                    <div className='w-full'>
                        <label htmlFor="townOrCity">Town Or City</label>
                        <input
                            type="text"
                            id="townOrCity"
                            name="townOrCity"
                            className={Inputstyle}
                            value={address.townOrCity}
                            onChange={e => setAddress({...address,townOrCity: e.target.value })}
                        />
                    </div>
                    <div className='w-full'>
                        <label htmlFor="postcode">Postcode</label>
                        <input
                            type="text"
                            id="postcode"
                            name="postcode"
                            className={Inputstyle}
                            value={address.postcode}
                            onChange={e => setAddress({...address,postcode: e.target.value })}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}
