import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Page404() {
    const navigate = useNavigate()
  return (
    <div className='w-full h-screen flex justify-center items-center'>
        <div className='text-center'>
            <img src="/img/404.gif" alt="Page Not Found" className='w-full md:w-1/2 mx-auto'/>
            <button onClick={_=> navigate(-1)} className='p-2 px-4 text-white rounded-lg hover:bg-purple-900 transition-all bg-purple-800 text-lg font-medium'>Go To Back</button>
        </div>
    </div>
  )
}
