import React, { useEffect, useState } from 'react'
import Button from '../../UI/Button';
import { TbMenuDeep } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { RiLoader5Line } from 'react-icons/ri'
import { getNotesApi, getTimelineApi, saveNotesApi, saveSolictorApi, saveStatusApi } from '../../libs/apis';
import toast from 'react-hot-toast';
import { CiEraser, CiSaveDown1, CiStickyNote, CiUser, CiViewTimeline } from "react-icons/ci";
import Modal from '../../UI/Modal';
import { FaDotCircle } from "react-icons/fa";
const apiDomain = process.env.REACT_APP_API_DOMAIN

const status = [
    {
        value: 1,
        label: "New",
        stages: [
            { value: 0, label: "-- Select --" },
            { value: 10, label: "Appointment Booked" },
            { value: 11, label: "Awaiting Evidence" },
            { value: 12, label: "Awaiting evidence - document collector booked" },
            { value: 13, label: "Awaiting evidence - document collector required" },
            { value: 14, label: "Awaiting evidence - pictures received" },
            { value: 15, label: "Callback arranged" },
            { value: 16, label: "Callback missed" },
            { value: 17, label: "Images Received" },
            { value: 18, label: "No answer" },
            { value: 19, label: "No answer - check timeline" },
            { value: 20, label: "Not Answered Since Case Created" },
            { value: 21, label: "Number already in system" },
            { value: 22, label: "Pictures received" },
            { value: 23, label: "Potential Case" }
        ],
    },
    {
        value: 2,
        label: "Closed",
        stages: [
            { value: 0, label: "-- Select --" },
            { value: 20, label: "Already claiming" },
            { value: 21, label: "Client Hung Up" },
            { value: 22, label: "Council Actively Involved" },
            { value: 23, label: "Council Have Repaired Property" },
            { value: 24, label: "Did not make enquiry" },
            { value: 25, label: "Disconnected number" },
            { value: 26, label: "Duplicate" },
            { value: 27, label: "Leaving Property" },
            { value: 28, label: "Never Answered" },
            { value: 29, label: "No disrepair" },
            { value: 30, label: "No Prospects Of Success" },
            { value: 31, label: "No reporting history" },
            { value: 32, label: "Not enough damage" },
            { value: 33, label: "Not interested" },
            { value: 34, label: "Pests only" },
            { value: 35, label: "Private tenant" },
            { value: 36, label: "Rent arrears" },
            { value: 37, label: "Repairs already carried out" },
            { value: 38, label: "Scotland" },
            { value: 39, label: "Temporary accommodation" },
            { value: 40, label: "Wrong number" }
        ],
    },
    {
        value: 3,
        label: "Pending",
        stages: [
            { value: 0, label: "-- Select --" },
            { value: 30, label: "Callback arranged" },
            { value: 31, label: "Callback Rearranged" },
            { value: 32, label: "Case Created" },
            { value: 33, label: "Case Hotkeyed" },
            { value: 34, label: "Case In Vetting" },
            { value: 35, label: "Case sent to solicitor" },
            { value: 36, label: "Case sent to vetting" },
            { value: 37, label: "ESign returned" },
            { value: 38, label: "ESign sent" },
            { value: 39, label: "Hotkey requested" },
            { value: 40, label: "More information required" },
            { value: 41, label: "On call with client" },
            { value: 42, label: "Supervisor Review Required" }
        ],
    },
    {
        value: 4,
        label: "Accepted",
        stages: [
            { value: 0, label: "-- Select --" },
            { value: 40, label: "Case Accepted" },
            { value: 41, label: "ESign sent" }
        ],
    },
    {
        value: 5,
        label: "Retained",
        stages: [
            { value: 0, label: "-- Select --" },
            { value: 50, label: "Back On Board" },
            { value: 51, label: "ESign returned" },
            { value: 52, label: "Esign Returned - More Images Required" }
        ],
    },
    {
        value: 6,
        label: "Rejected",
        stages: [
            { value: 0, label: "-- Select --" },
            { value: 60, label: "Already claiming" },
            { value: 61, label: "Arrears Too High" },
            { value: 62, label: "Client Cancelled Instruction" },
            { value: 63, label: "Client Doesn't Wish To Proceed" },
            { value: 64, label: "Client Instructed Other Solicitors" },
            { value: 65, label: "Duplicate Case" },
            { value: 66, label: "Issues With Quantum" },
            { value: 67, label: "Issues With Reporting History" },
            { value: 68, label: "Not interested" },
            { value: 69, label: "Other (See Notes)" },
            { value: 70, label: "Private Landlord" }
        ],
    },
    {
        value: 7,
        label: "Clawedback",
        stages: [
            { value: 0, label: "-- Select --" },
            { value: 70, label: "Case Defended" },
            { value: 71, label: "Client Doesn't Wish To Proceed" },
            { value: 72, label: "Client Instructed Other Solicitors" },
            { value: 73, label: "Client Unresponsive" },
            { value: 74, label: "Low Prospects" }
        ]
    }
]

const noteObj = []

export default function SideBar({ data, user, matterId }) {
    const [matter, setMatter] = useState({})
    const [selectedStatus, setSelectedStatus] = useState(status[0]);
    const [selectedStage, setSelectedStage] = useState(selectedStatus.stages[0]);
    const [statusUpdate, setStatusUpdate] = useState({
        status: 0,
        stage: 0
    })
    const [col, setCol] = useState(false)
    const [loading, setLoading] = useState(false)
    const [timeModal, setTimeModal] = useState(false)
    const [timeline, setTimeline] = useState(null)
    const [noteModal, setNoteModal] = useState(false)
    const [note, setNote] = useState(noteObj)
    const [noteField, setNoteField] = useState('')

    const handleStatusChange = (event) => {
        const selectedStatusValue = parseInt(event.target.value, 10);
        const selectedStatus = status.find(status => status.value === selectedStatusValue);
        setSelectedStatus(selectedStatus);
        setSelectedStage(selectedStatus.stages[0]);
        setStatusUpdate({ status: selectedStatusValue, stage: 0 })
    };

    const handleStageChange = (event) => {
        const selectedStageValue = parseInt(event.target.value, 10);
        const selectedStage = selectedStatus.stages.find(stage => stage.value === selectedStageValue);
        setSelectedStage(selectedStage);
        setStatusUpdate({ ...statusUpdate, stage: selectedStageValue })
    };

    useEffect(() => {
        if (matter?.status) {
            let selectedStatusValue = parseInt(matter?.status)
            let selectedStageValue = parseInt(matter?.stage)
            const selectedStatus = status.find(status => status.value === selectedStatusValue);
            setSelectedStatus(selectedStatus);
            const selectedStage = selectedStatus.stages.find(stage => stage.value === selectedStageValue);
            setSelectedStage(selectedStage);
            setStatusUpdate({ status: selectedStatusValue, stage: selectedStageValue })
        }
    }, [matter])

    useEffect(() => {
        if (data) {
            setMatter(data)
        }
    }, [data])

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '
    let collapse = ' bg-white p-4 backdrop:bg-slate-500/50 fixed top-0 left-0 z-10 pt-28 w-full h-full lg:static lg:p-0'

    const saveData = async () => {
        toast.loading('Loading...', {
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveStatusApi({ userId: user.id, role: user.role, matterId, data: statusUpdate })
        if (res?.status) {
            toast.dismiss();
            toast.success('Status has been changed.', {
                duration: 5000,
                position: 'bottom-center',
            })
            setMatter(res?.data)
            getTimelineF({ userId: user.id, role: user.role, matterId })
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    async function getTimelineF(data) {
        let res = await getTimelineApi(data)
        if (res?.status) {
            setTimeline(res.data)
        }
    }
    async function getNoteDataF(data) {
        let res = await getNotesApi(data)
        if (res?.status) {
            setNote(res.data)
        }
    }

    useEffect(() => {
        getTimelineF({ userId: user.id, role: user.role, matterId })
        getNoteDataF({ userId: user.id, role: user.role, matterId })
    }, [matterId, user])

    const timeModalF = () => setTimeModal(false)
    const noteModalF = () => setNoteModal(false)

    const createNote = async () => {
        if (!noteField) {
            alert('Write note')
            return
        }
        toast.loading('Loading...', {
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveNotesApi({ userId: user.id, role: user.role, matterId, data: noteField })
        if (res?.status) {
            toast.dismiss();
            toast.success('Note has been created.', {
                duration: 5000,
                position: 'bottom-center',
            })
            getNoteDataF({ userId: user.id, role: user.role, matterId })
            getTimelineF({ userId: user.id, role: user.role, matterId })
            setLoading(false)
            setNoteField('')
        } else {
            toast.dismiss();
            toast.error(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    const sendToSolictor = async () => {
        toast.loading('Loading...', {
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveSolictorApi({ userId: user.id, role: user.role, solictor: 1, matterId })
        if (res?.status) {
            toast.dismiss();
            toast.success('Matter send to solictor.', {
                duration: 5000,
                position: 'bottom-center',
            })
            setMatter(res.data)
            getTimelineF({ userId: user.id, role: user.role, matterId })
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    const removeSolictor = async () => {
        toast.loading('Loading...', {
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveSolictorApi({ userId: user.id, role: user.role, solictor: 0, matterId })
        if (res?.status) {
            toast.dismiss();
            toast.success('Removed solictor.', {
                duration: 5000,
                position: 'bottom-center',
            })
            setMatter(res.data)
            getTimelineF({ userId: user.id, role: user.role, matterId })
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <>
            <div className='lg:border-2 border-slate-100 lg:rounded-lg lg:min-w-[250px] h-[60px] lg:h-auto xl:min-w-[300px] w-full shadow-lg bg-white p-4 lg:p-7 fixed left-0 top-[76px] lg:sticky lg:top-5 '>
                <div className='flex w-full justify-between items-center h-full cursor-pointer hover:opacity-60 lg:hidden' onClick={_ => setCol(true)}>
                    <div>
                        <label htmlFor="">Reference: <strong>{matter?.reference}</strong></label>
                        <div className=' bg-orange-400 text-white p-1 text-sm px-3 ml-2 text-center rounded-full inline-block'>
                            {selectedStatus.label} - {selectedStage.label}
                        </div>
                    </div>
                    <TbMenuDeep className='text-3xl' />
                </div>
                <div className={col ? collapse : 'hidden lg:block' + collapse}>
                    <div className='relative'>
                        <button className='cursor-pointer block lg:hidden text-3xl absolute -top-3 right-2 hover:opacity-60' onClick={_ => setCol(false)}>
                            <RxCross2 />
                        </button>
                        {
                            user?.role === 33 ? (
                                <>
                                    <div className='w-full'>
                                        <label htmlFor="">Current Status:</label>
                                        <select id="statusSelect" value={selectedStatus.value} onChange={handleStatusChange} className={Inputstyle}>
                                            {status.map(status => (
                                                <option key={status.value} value={status.value}>{status.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='w-full'>
                                        <select id="stageSelect" value={selectedStage.value} onChange={handleStageChange} className={Inputstyle}>
                                            {selectedStatus.stages.map(stage => (
                                                <option key={stage.value} value={stage.value}>{stage.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <Button size='small' text={
                                        loading ? (
                                            <>
                                                <RiLoader5Line className='animate-spin text-xl' />
                                            </>
                                        ) : 'Save'
                                    } className="mt-3"
                                        disabled={loading}
                                        onClick={saveData}
                                    />
                                </>
                            ) : (
                                <div className='w-full'>
                                    <label htmlFor="" className='mr-3'>Current Status:</label>
                                    <div className=' bg-orange-400 text-white p-1 px-4 text-center rounded-full mt-2 inline-block'>
                                        {selectedStatus.label}{parseInt(selectedStage.value) !== 0 ? ' - ' + selectedStage.label : ''}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <hr className='mt-4' />
                    <div className='pt-4'>
                        <label htmlFor="" className='text-gray-500'>Reference: <span className='text-gray-800'>{matter?.reference}</span></label>
                    </div>
                    <div className='py-2'>
                        <label htmlFor="" className='text-gray-500'>Type: <span className='text-gray-800'>{matter?.type === '1' ? 'Housing Disrepair' : ''}</span></label>
                    </div>
                    <div className='pb-4'>
                        <label htmlFor="" className='text-gray-500'>Created At: <span className='text-gray-800'>{matter?.createdAt}</span></label>
                    </div>
                    <div className='pb-4'>
                        <label htmlFor="" className='text-gray-500'>Last Updated: <span className='text-gray-800'>{matter?.updatedAt}</span></label>
                    </div>
                    <hr className='mb-4' />
                    <div>
                        <button className='block py-2 hover:opacity-70' onClick={_ => setNoteModal(true)}>
                            <CiStickyNote className='inline-block text-xl mr-1' />
                            Note
                        </button>
                        <button className='block py-2 hover:opacity-70 mb-2' onClick={_ => setTimeModal(true)}>
                            <CiViewTimeline className='inline-block text-xl mr-1' />
                            Timeline
                        </button>

                        {
                            user?.role === 22 && (
                                <>
                                    <hr className=' mb-2' />
                                    {
                                        parseInt(matter?.solictor) === 1 ? (
                                            <>
                                                <p>
                                                    Sended to solictor
                                                </p>
                                                <button onClick={removeSolictor} className='text-sm hover:opacity-70 border rounded-md p-1 px-3 mt-2'><CiEraser className='text-lg inline-block' /> Remove Solictor</button>
                                            </>
                                        ) : (
                                            <button className='block py-2 hover:opacity-70' onClick={sendToSolictor}>
                                                <CiUser className='inline-block text-xl mr-1' />
                                                Send to Solictor
                                            </button>
                                        )
                                    }
                                </>
                            )
                        }
                        {
                            user?.role === 33 && (
                                <>
                                    <hr className=' mb-5' />
                                    <a href={`${apiDomain}/api/word/download.php?matterId=${matterId}&userId=${user?.id}&role=${user?.role}`} target='_new' className='inline-block py-2 px-4 hover:opacity-70 border border-slate-300 rounded-md'>
                                        <CiSaveDown1 className='inline-block text-xl mr-1' />
                                        Download Matter
                                    </a>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <Modal onShow={timeModal} onHide={timeModalF}>
                <h4 className='text-lg font-medium mb-3'>Timeline</h4>
                <hr />
                <div className='border-l-4 border-slate-200 ml-4 grid grid-cols-1 gap-8 mt-4'>
                    {
                        timeline && timeline.map((t, i) => (
                            <div key={i} className='flex gap-2 md:gap-4 items-center w-full justify-between'>
                                <div className='flex gap-2 md:gap-4 items-cente'>
                                    <FaDotCircle className='text-2xl text-purple-400 inline-block bg-white outline outline-white -ml-[14px] rounded-full' />  {t.details} {t?.firstName}
                                </div>
                                <span>{t?.createdAt}</span>
                            </div>
                        ))
                    }
                </div>
            </Modal>
            <Modal onShow={noteModal} onHide={noteModalF}>
                <h4 className='text-lg font-medium mb-3'>Note</h4>
                <hr />
                <div className=' mt-4'>
                    {
                        note?.length > 0 && note.map((n, i) => (
                            <div key={i} className='border border-slate-300 rounded-lg p-2 lg:p-4 mb-3'>
                                <p>{n.content}</p>
                                <div className='flex gap-2 justify-between border-t pt-3 mt-4'>
                                    <span>Created By: {n.firstName}</span>
                                    <span>Created At: {n.createdAt}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className='border border-slate-300 p-4 rounded-lg'>
                    <textarea className={Inputstyle} name="note" id="note" rows="4" placeholder='Write note' value={noteField} onChange={e => setNoteField(e.target.value)}></textarea>
                    <Button text={
                        loading ? (
                            <>
                                <RiLoader5Line className='animate-spin text-xl' />
                            </>
                        ) : 'Add Note'} size="small" className="mt-4" onClick={createNote} disabled={loading} />
                </div>
            </Modal>
        </>
    )
}
