const apiDomain = process.env.REACT_APP_API_DOMAIN

export const sendEmailCode = async (email) => {
    const res = await fetch(`${apiDomain}/api/admin/sendVerificationCode.php`, {
        method: 'POST',
        body: JSON.stringify({ email })
    })
    const json = await res.json()
    return json;
}

export const verifyCode = async (email, code) => {
    const res = await fetch(`${apiDomain}/api/admin/verifyAdminEmail.php`, {
        method: 'POST',
        body: JSON.stringify({ email, code })
    })
    const json = await res.json()
    return json;
}

export const tokenVerify = async (token) => {
    const res = await fetch(`${apiDomain}/api/admin/tokenVerification.php`, {
        method: 'POST',
        body: JSON.stringify({ token })
    })
    const json = await res.json()
    return json;
}

export const createUserApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/admin/createUsers.php`, {
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
}

export const updateUserStatusApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/admin/updateUserStatus.php`, {
        method: 'PUT',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
}

export const getUserApi = async (adminId, adminRole, filter=null) => {
    const res = await fetch(`${apiDomain}/api/admin/getUsers.php?adminUniqueId=${adminId}&adminRole=${adminRole}&role=${filter}`, {
        method: 'GET',
    })
    const json = await res.json()
    return json;
}

export const updateUserApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/admin/updateUsers.php`, {
        method: 'PUT',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
}

export const updateUserPasswordApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/admin/changePassowrd.php`, {
        method: 'PUT',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
}

export const deleteUserApi = async (data) => {
    const res = await fetch(`${apiDomain}/api/admin/deleteUser.php`, {
        method: 'DELETE',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json;
}

export const userReportApi = async ({adminUniqueId, adminRole}) => {
    const res = await fetch(`${apiDomain}/api/admin/userReport.php?adminUniqueId=${adminUniqueId}&adminRole=${adminRole}`, {
        method: 'GET',
    })
    const json = await res.json()
    return json;
}



