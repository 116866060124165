import React from 'react'

export default function Table({ heading, children, className }) {
    className = className ? 'w-full ' + className : 'w-full text-center'
    return (
        <div className='overflow-auto w-full'>
            <table className={className}>
                <thead>
                    <tr className='border-b-[1px]'>
                        {
                            heading?.map((h, i) => (
                                <th className='p-3 min-w-[100px]' key={i}>{h}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    )
}
