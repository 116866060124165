import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HiOutlineBriefcase, HiOutlineHome, HiOutlineLogout, HiOutlineMenu, HiOutlineUser, HiOutlineUserCircle, HiOutlineX } from "react-icons/hi";
import AdminAuth from '../../Context/AdminAuth/AdminContext'

export default function Menu() {
    const [menu, setMenu] = useState(false)
    const location = useLocation();
    let style = 'text-center block text-md p-3 w-full rounded-lg hover:bg-purple-800 transition-all duration-300 ';
    let backDrop = 'fixed backdrop-blur-sm w-full h-screen top-0 left-0 transition-all lg:hidden duration-300 opacity-0 ';
    let menuStyle = 'lg:py-6 lg:block fixed lg:static bg-purple-900 top-0 p-5 py-16 lg:p-0 h-screen lg:h-auto w-full sm:w-1/2 lg:w-full transition-all duration-300 ';

    const navigate = useNavigate();

    const {setAdmin, setToken, setLoginStatus} = useContext(AdminAuth)

    const logout = () => {
        setAdmin({ email: '', id: '', role: ''})
        setToken({})
        setLoginStatus(false)
        localStorage.removeItem('token')
        setTimeout(()=>{
            navigate('/dashboard/login')
        },3000)
    }

    return (
        <>
            <button className='text-3xl block lg:hidden' onClick={_=> setMenu(true)}>
                <HiOutlineMenu />
            </button>
            <div className={menu ? backDrop + 'block opacity-100': backDrop + 'hidden'} onClick={_=> setMenu(false)}></div>
            <div className={menu ? menuStyle + 'left-0': menuStyle + '-left-full'}>
                <HiOutlineX className="lg:hidden absolute top-4 right-4 text-white text-3xl cursor-pointer" onClick={_=> setMenu(false)}/>
                {/* logout */}
                <div className='flex justify-between bg-purple-700 px-3 py-2 rounded-lg mb-5'>
                    <div className='flex items-center gap-2'>
                        <HiOutlineUserCircle className='text-xl'/>
                        <h3>Administrator</h3>
                    </div>
                    <button className='text-xl' onClick={logout}>
                        <HiOutlineLogout />
                    </button>
                </div>
                <ul className=''>
                    <li className='mb-3'>
                        <Link to='/dashboard' className={location.pathname === '/dashboard' ? style + ' bg-purple-800' : style}>
                            <HiOutlineHome className='text-2xl inline-block float-left' />
                            Dashboard
                        </Link>
                    </li>
                    <li className='mb-3'>
                        <Link to='/dashboard/users' className={location.pathname === '/dashboard/users' ? style + ' bg-purple-800' : style}>
                            <HiOutlineUser className='text-2xl inline-block float-left' />
                            Users
                        </Link>
                    </li>
                    <li className='mb-3'>
                        <Link to='/dashboard/matters' className={location.pathname === '/dashboard/mattes' ? style + ' bg-purple-800' : style}>
                            <HiOutlineBriefcase className='text-2xl inline-block float-left' />
                            Matters
                        </Link>
                    </li>
                </ul>
                <p className='absolute bottom-5 left-0 text-center w-full'>Powered By <a href='https://antech.pk' target='_new'>ANTech</a></p>
            </div>
        </>
    )
}
