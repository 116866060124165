import React from 'react'
import Button from './Button'

export default function Modal({ onShow, onHide, closeBtn = 'Back', mainBtn, children, onClick, mainBtnDisabled= false }) {
    let style = 'fixed top-0 left-0 w-full h-screen p-2 overflow-auto z-50 lg:z-auto '
    if(onShow){
        style = style + ''
    } else {
        style = style + 'hidden'
    }
    return (
        <div className={style}>
            <div className='w-full h-screen backdrop-blur-[2px] absolute top-0 left-0'></div>
            <div className='lg:w-1/2 md:w-4/5 w-full bg-white relative shadow-lg rounded-lg z-10 mx-auto lg:mt-10 border-2 border-slate-100'>
                <div className='w-full p-3 md:p-5 min-h-[200px]'>
                    { children }
                </div>
                <div className='w-full p-3 flex justify-end border-t-2 border-slate-100'>
                    <Button type='button' text={closeBtn} size='small' className='bg-slate-400 hover:bg-slate-500' onClick={onHide}/>
                    {mainBtn && <Button type='button' text={mainBtn} size='small' className='ml-3' onClick={onClick} disabled={mainBtnDisabled}/>}
                </div>
            </div>
        </div>
    )
}
