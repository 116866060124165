import { useEffect, useState } from 'react';
import UserAuth from './UserContext';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { userVerificationApi } from '../../libs/apis';

const userObj = { name: '', email: '', id: '', role: ''}

export default function UserAuthProvider({ children }) {
  const [user, setUser] = useState(userObj)
  const [token, setToken] = useState({})
  const [loginStatus, setLoginStatus] = useState(false)
  
  const navigate = useNavigate();
  
  useEffect(()=>{
    const tokenVeri = async () => {
      const tokenLocal = JSON.parse(localStorage.getItem('userToken'))
      let res = await userVerificationApi(tokenLocal?.token)

      if (tokenLocal?.status) {
        if(res?.status){
          setToken(tokenLocal)
          const decodeData = jwtDecode(tokenLocal.token)
          setLoginStatus(true)
          setUser({ name:decodeData?.name, email: decodeData?.email, id: decodeData?.uniqueId, role: decodeData?.role })
        } else {
          setLoginStatus(false)
          setUser(userObj)
          navigate('/login');
        }
      } else {
        setLoginStatus(false)
        setUser(userObj)
        navigate('/login');
      }
    }
    tokenVeri()
  },[setUser, setToken, setLoginStatus, navigate])


  return (
    <UserAuth.Provider value={{user, setUser, loginStatus, setLoginStatus, token, setToken}}>
      { children }
    </UserAuth.Provider>
  );
}