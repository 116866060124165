import React, { useContext } from 'react'
import Menu from './Components/Menu'
import { Toaster } from 'react-hot-toast'
import AdminAuthProvider from '../Context/AdminAuth/AdminAuthProvieder'
import AdminAuth from '../Context/AdminAuth/AdminContext'

export default function DashboardLayout({ children }) {
  return (
    <AdminAuthProvider>
      <DashboardLayoutSet>
        { children }
      </DashboardLayoutSet>
    </AdminAuthProvider>
  )
}

export function DashboardLayoutSet({ children }) {
  const {loginStatus} = useContext(AdminAuth)

  if (!loginStatus) {
    return (
      <div className="flex flex-wrap">
            <div className="relative bg-purple-900 text-white w-full lg:w-1/5 lg:h-screen p-5 flex lg:block justify-between shadow-lg lg:shadow-none">
                <div className='text-xl lg:text-2xl font-bold lg:text-center block'>
                  <div className='min-h-[25px] w-[150px] rounded-lg bg-slate-200 animate-pulse'></div>
                </div>
                <div className='my-6 hidden lg:block'>
                  <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-700 animate-pulse'></div>
                  <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-800 animate-pulse'></div>
                  <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-800 animate-pulse'></div>
                  <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-800 animate-pulse'></div>
                  <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-800 animate-pulse'></div>
                </div>
            </div>
            <div className="flex-none w-full lg:w-4/5 p-5">
                <div className='min-h-[40px] mb-10 w-full rounded-lg bg-slate-200 animate-pulse'></div>
                <div className='min-h-[50vh] w-full rounded-lg bg-slate-200 animate-pulse'></div>
            </div>
        </div>
    )
  }

  return (
      <>
        <div className="flex flex-wrap">
            <div className="relative bg-purple-900 text-white w-full lg:w-1/5 lg:h-screen p-5 flex lg:block justify-between shadow-lg lg:shadow-none z-50">
                <div className='text-xl lg:text-2xl font-bold lg:text-center block'>SA Assistance</div>
                <Menu/>
            </div>
            <div className="flex-none w-full lg:w-4/5 p-5">
                { children }
            </div>
        </div>
        <Toaster />
      </>
  )
}
