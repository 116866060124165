import React, { useState } from 'react'
import { CiTrash } from "react-icons/ci";
import { SlEye } from "react-icons/sl";
import { RiLoader5Line } from 'react-icons/ri'
import { RxDownload } from 'react-icons/rx';
import { deleteFileApi } from '../../libs/apis';
import toast from 'react-hot-toast';

const apiDomain = process.env.REACT_APP_API_DOMAIN

export default function FileCard({data, icon, dataReturn, user, matterId}) {
    const [loading, setLoading] = useState(false)

    async function deletFile(fileId, name) {
        const areYouSure = window.confirm("Are you sure? you delete this [" + name + "] file");
        if(!areYouSure){
            return
        }

        setLoading(true)
        const res = await deleteFileApi({userId:user.id, role: user.role, matterId, fileId })
        toast.dismiss();
        if (res?.status) {
            toast.success('File deleted successfully.', {
                duration: 5000,
                position: 'bottom-center',
            })
            dataReturn(res?.data);
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message, {
                duration: 5000,
                position: 'bottom-center',
            })
            setLoading(false)
        }
    }
    return (
        <div className='overflow-hidden relative group w-full border border-slate-300 rounded-md p-3 lg:p-6 min-h-[100px] text-xl cursor-pointer hover:border-slate-400 flex justify-center items-center'>
            <div className='text-2xl md:text-3xl group-hover:hidden text-center relative'>
                <div className='mx-auto inline-block'>{icon}</div>
                <p className='text-sm line-clamp-2'>{data.alt}</p>
            </div>
            <div className='hidden group-hover:flex md:gap-3'>
                <a href={apiDomain + data.file_url} target='_new' className='p-2 md:p-3 hover:text-purple-900'>
                    <SlEye />
                </a>
                <a href={apiDomain + data.file_url} target='_new' className='p-2 md:p-3 hover:text-purple-900' download>
                    <RxDownload />
                </a>
                <button className='p-2 md:p-3 hover:text-purple-900' onClick={_ => deletFile(data.id, data.alt)}>
                    <CiTrash />
                </button>
            </div>
            {
                loading && (
                    <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-white/70'>
                        <RiLoader5Line className='animate-spin' />
                    </div>
                )
            }
        </div>
    )
}
