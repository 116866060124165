import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { RiLoader5Line } from 'react-icons/ri'
import { saveClaimApi } from '../../libs/apis'

let obj = {
    disrepair_details: "",
    has_loose_or_broken_roof_tiles: "",
    has_loose_or_broken_roof_tiles_details: "",
    has_cracks_to_external_walls: "",
    has_cracks_to_external_walls_details: "",
    has_cracks_to_internal_walls: "",
    has_cracks_to_internal_walls_details: "",
    has_damp_or_mould: "",
    has_damp_or_mould_details: "",
    has_door_or_window_frame_rot: "",
    has_door_or_window_frame_rot_details: "",
    has_faulty_electrics: "",
    has_faulty_electrics_details: "",
    has_faulty_boiler: "",
    has_faulty_boiler_details: "",
    has_leaking_pipes: "",
    has_leaking_pipes_details: "",
    has_broken_sanitary_fixtures: "",
    has_broken_sanitary_fixtures_details: "",
    has_floor_boards_rotting: "",
    has_floor_boards_rotting_details: "",
    has_vermin: "",
    has_vermin_details: "",
    has_damage_to_property: "",
    has_damage_to_property_details: "",
    has_disrepair_caused_health_issues: "",
    has_disrepair_caused_health_issues_details: "",
    has_disrepair_inconvenience: "",
    has_disrepair_inconvenience_details: "",
}
export default function ClaimDetails({ data, user, matterId }) {
    const [claim, setClaim] = useState(obj)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(data?.id){
            setClaim(data)
        }
    }, [data])

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '

    const saveData = async () => {
        toast.loading('Loading...',{
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveClaimApi({userId:user.id, role: user.role, matterId, data: claim })
        if(res?.status) {
            toast.dismiss();
            toast.success('Claim record has been updated.',{
              duration:5000,
              position: 'bottom-center',
            })
            setClaim(res?.data)
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message,{
              duration:5000,
              position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }
    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7 mt-5'>
                <h3 className='text-lg pb-3 font-semibold'>Claim Details</h3>
                <hr />
                <div className='pt-4'>
                    <label htmlFor="disrepair_details">Disrepair details</label>
                    <textarea
                        id="disrepair_details"
                        name="disrepair_details"
                        className={Inputstyle}
                        value={claim?.disrepair_details}
                        onChange={e => setClaim({ ...claim, disrepair_details: e.target.value })}
                        rows={3}
                        >
                    </textarea>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4'>
                    <div className='pt-4'>
                        <label htmlFor="has_loose_or_broken_roof_tiles">Is there loose or broken roof tiles?</label>
                        <select
                            id="has_loose_or_broken_roof_tiles"
                            name="has_loose_or_broken_roof_tiles"
                            className={Inputstyle}
                            value={claim?.has_loose_or_broken_roof_tiles}
                            onChange={e => setClaim({ ...claim, has_loose_or_broken_roof_tiles: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_loose_or_broken_roof_tiles === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_loose_or_broken_roof_tiles_details">If so, please give details</label>
                                <textarea
                                    id="has_loose_or_broken_roof_tiles_details"
                                    name="has_loose_or_broken_roof_tiles_details"
                                    className={Inputstyle}
                                    value={claim?.has_loose_or_broken_roof_tiles_details}
                                    onChange={e => setClaim({ ...claim, has_loose_or_broken_roof_tiles_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_cracks_to_external_walls">Is there cracks to external walls?</label>
                        <select
                            id="has_cracks_to_external_walls"
                            name="has_cracks_to_external_walls"
                            className={Inputstyle}
                            value={claim?.has_cracks_to_external_walls}
                            onChange={e => setClaim({ ...claim, has_cracks_to_external_walls: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_cracks_to_external_walls === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_cracks_to_external_walls_details">If so, please give details</label>
                                <textarea
                                    id="has_cracks_to_external_walls_details"
                                    name="has_cracks_to_external_walls_details"
                                    className={Inputstyle}
                                    value={claim?.has_cracks_to_external_walls_details}
                                    onChange={e => setClaim({ ...claim, has_cracks_to_external_walls_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_cracks_to_internal_walls">Is there cracks to internal walls?</label>
                        <select
                            id="has_cracks_to_internal_walls"
                            name="has_cracks_to_internal_walls"
                            className={Inputstyle}
                            value={claim?.has_cracks_to_internal_walls}
                            onChange={e => setClaim({ ...claim, has_cracks_to_internal_walls: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_cracks_to_internal_walls === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_cracks_to_internal_walls_details">If so, please give details</label>
                                <textarea
                                    id="has_cracks_to_internal_walls_details"
                                    name="has_cracks_to_internal_walls_details"
                                    className={Inputstyle}
                                    value={claim?.has_cracks_to_internal_walls_details}
                                    onChange={e => setClaim({ ...claim, has_cracks_to_internal_walls_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_damp_or_mould">Is there damp or mould?</label>
                        <select
                            id="has_damp_or_mould"
                            name="has_damp_or_mould"
                            className={Inputstyle}
                            value={claim?.has_damp_or_mould}
                            onChange={e => setClaim({ ...claim, has_damp_or_mould: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_damp_or_mould === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_damp_or_mould_details">If so, please give details</label>
                                <textarea
                                    id="has_damp_or_mould_details"
                                    name="has_damp_or_mould_details"
                                    className={Inputstyle}
                                    value={claim?.has_damp_or_mould_details}
                                    onChange={e => setClaim({ ...claim, has_damp_or_mould_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_door_or_window_frame_rot">Are the doors or window frames rotting?</label>
                        <select
                            id="has_door_or_window_frame_rot"
                            name="has_door_or_window_frame_rot"
                            className={Inputstyle}
                            value={claim?.has_door_or_window_frame_rot}
                            onChange={e => setClaim({ ...claim, has_door_or_window_frame_rot: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_door_or_window_frame_rot === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_door_or_window_frame_rot_details">If so, please give details</label>
                                <textarea
                                    id="has_door_or_window_frame_rot_details"
                                    name="has_door_or_window_frame_rot_details"
                                    className={Inputstyle}
                                    value={claim?.has_door_or_window_frame_rot_details}
                                    onChange={e => setClaim({ ...claim, has_door_or_window_frame_rot_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_faulty_electrics">Are there faulty electrics?</label>
                        <select
                            id="has_faulty_electrics"
                            name="has_faulty_electrics"
                            className={Inputstyle}
                            value={claim?.has_faulty_electrics}
                            onChange={e => setClaim({ ...claim, has_faulty_electrics: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_faulty_electrics === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_faulty_electrics_details">If so, please give details</label>
                                <textarea
                                    id="has_faulty_electrics_details"
                                    name="has_faulty_electrics_details"
                                    className={Inputstyle}
                                    value={claim?.has_faulty_electrics_details}
                                    onChange={e => setClaim({ ...claim, has_faulty_electrics_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_faulty_boiler">Is the boiler faulty?</label>
                        <select
                            id="has_faulty_boiler"
                            name="has_faulty_boiler"
                            className={Inputstyle}
                            value={claim?.has_faulty_boiler}
                            onChange={e => setClaim({ ...claim, has_faulty_boiler: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_faulty_boiler === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_faulty_boiler_details">If so, please give details</label>
                                <textarea
                                    id="has_faulty_boiler_details"
                                    name="has_faulty_boiler_details"
                                    className={Inputstyle}
                                    value={claim?.has_faulty_boiler_details}
                                    onChange={e => setClaim({ ...claim, has_faulty_boiler_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_leaking_pipes">Are any pipes leaking?</label>
                        <select
                            id="has_leaking_pipes"
                            name="has_leaking_pipes"
                            className={Inputstyle}
                            value={claim?.has_leaking_pipes}
                            onChange={e => setClaim({ ...claim, has_leaking_pipes: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_leaking_pipes === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_leaking_pipes_details">If so, please give details</label>
                                <textarea
                                    id="has_leaking_pipes_details"
                                    name="has_leaking_pipes_details"
                                    className={Inputstyle}
                                    value={claim?.has_leaking_pipes_details}
                                    onChange={e => setClaim({ ...claim, has_leaking_pipes_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_broken_sanitary_fixtures">Are any sanitary fixtures broken?</label>
                        <select
                            id="has_broken_sanitary_fixtures"
                            name="has_broken_sanitary_fixtures"
                            className={Inputstyle}
                            value={claim?.has_broken_sanitary_fixtures}
                            onChange={e => setClaim({ ...claim, has_broken_sanitary_fixtures: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_broken_sanitary_fixtures === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_broken_sanitary_fixtures_details">If so, please give details</label>
                                <textarea
                                    id="has_broken_sanitary_fixtures_details"
                                    name="has_broken_sanitary_fixtures_details"
                                    className={Inputstyle}
                                    value={claim?.has_broken_sanitary_fixtures_details}
                                    onChange={e => setClaim({ ...claim, has_broken_sanitary_fixtures_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_floor_boards_rotting">Are any floorboards roting?</label>
                        <select
                            id="has_floor_boards_rotting"
                            name="has_floor_boards_rotting"
                            className={Inputstyle}
                            value={claim?.has_floor_boards_rotting}
                            onChange={e => setClaim({ ...claim, has_floor_boards_rotting: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_floor_boards_rotting === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_floor_boards_rotting_details">If so, please give details</label>
                                <textarea
                                    id="has_floor_boards_rotting_details"
                                    name="has_floor_boards_rotting_details"
                                    className={Inputstyle}
                                    value={claim?.has_floor_boards_rotting_details}
                                    onChange={e => setClaim({ ...claim, has_floor_boards_rotting_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_vermin">Are there any vermin?</label>
                        <select
                            id="has_vermin"
                            name="has_vermin"
                            className={Inputstyle}
                            value={claim?.has_vermin}
                            onChange={e => setClaim({ ...claim, has_vermin: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_vermin === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_vermin_details">If so, please give details</label>
                                <textarea
                                    id="has_vermin_details"
                                    name="has_vermin_details"
                                    className={Inputstyle}
                                    value={claim?.has_vermin_details}
                                    onChange={e => setClaim({ ...claim, has_vermin_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_damage_to_property">Is there any damage to the property?</label>
                        <select
                            id="has_damage_to_property"
                            name="has_damage_to_property"
                            className={Inputstyle}
                            value={claim?.has_damage_to_property}
                            onChange={e => setClaim({ ...claim, has_damage_to_property: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_damage_to_property === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_damage_to_property_details">If so, please give details</label>
                                <textarea
                                    id="has_damage_to_property_details"
                                    name="has_damage_to_property_details"
                                    className={Inputstyle}
                                    value={claim?.has_damage_to_property_details}
                                    onChange={e => setClaim({ ...claim, has_damage_to_property_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_disrepair_caused_health_issues">Has the disrepair caused health issues?</label>
                        <select
                            id="has_disrepair_caused_health_issues"
                            name="has_disrepair_caused_health_issues"
                            className={Inputstyle}
                            value={claim?.has_disrepair_caused_health_issues}
                            onChange={e => setClaim({ ...claim, has_disrepair_caused_health_issues: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_disrepair_caused_health_issues === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_disrepair_caused_health_issues_details">If so, please give details</label>
                                <textarea
                                    id="has_disrepair_caused_health_issues_details"
                                    name="has_disrepair_caused_health_issues_details"
                                    className={Inputstyle}
                                    value={claim?.has_disrepair_caused_health_issues_details}
                                    onChange={e => setClaim({ ...claim, has_disrepair_caused_health_issues_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                    <div className='pt-4'>
                        <label htmlFor="has_disrepair_inconvenience">Has the disrepair caused inconvenience?</label>
                        <select
                            id="has_disrepair_inconvenience"
                            name="has_disrepair_inconvenience"
                            className={Inputstyle}
                            value={claim?.has_disrepair_inconvenience}
                            onChange={e => setClaim({ ...claim, has_disrepair_inconvenience: e.target.value })}
                        >
                            <option value="">-- Select --</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    {
                        claim?.has_disrepair_inconvenience === 'yes' && (
                            <div className='pt-4 md:col-span-2'>
                                <label htmlFor="has_disrepair_inconvenience_details">If so, please give details</label>
                                <textarea
                                    id="has_disrepair_inconvenience_details"
                                    name="has_disrepair_inconvenience_details"
                                    className={Inputstyle}
                                    value={claim?.has_disrepair_inconvenience_details}
                                    onChange={e => setClaim({ ...claim, has_disrepair_inconvenience_details: e.target.value })}
                                    rows={3}
                                    >
                                </textarea>
                            </div>
                        )
                    }
                </div>
                <hr className='mt-4' />
                <button className='mt-4 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center' onClick={saveData} disabled={loading}>
                    Save
                    {
                        loading && (
                            <RiLoader5Line className='animate-spin'/>
                        )
                    }
                </button>
            </div>
        </>
    )
}
