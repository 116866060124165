import React, { useEffect, useState } from 'react'
import { saveLandlordApi } from '../../libs/apis'
import { RiLoader5Line } from 'react-icons/ri'
import toast from 'react-hot-toast'

export default function Landlord({ data, user, matterId }) {
    const [landlord, setLandlord] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(data?.id){
            setLandlord(data)
        }
    }, [data])

    let Inputstyle = 'mt-2 py-2 px-4 md:py-2 md:px-4 w-full border-2 rounded-lg text-md outline-white focus:outline-purple-300 transition-all duration-300 read-only:text-gray-500 '

    const saveData = async () => {
        toast.loading('Loading...',{
            position: 'bottom-center',
        });
        setLoading(true)
        let res = await saveLandlordApi({userId:user.id, role: user.role, matterId, data: landlord.name })
        if(res?.status) {
            toast.dismiss();
            toast.success('Landlord record has been updated.',{
              duration:5000,
              position: 'bottom-center',
            })
            setLandlord(res?.data)
            setLoading(false)
        } else {
            toast.dismiss();
            toast.error(res?.message,{
              duration:5000,
              position: 'bottom-center',
            })
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <>
            <div className='border-2 border-slate-100 rounded-lg min-h-[200px] shadow-lg bg-white p-5 md:p-7 mt-5'>
                <h3 className='text-lg pb-3 font-semibold'>Landlord</h3>
                <hr />
                <div className='pt-4'>
                    <label htmlFor="name">Landlord Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className={Inputstyle}
                        value={landlord?.name}
                        onChange={e => setLandlord({ ...landlord, name: e.target.value })}
                    />
                </div>
                <hr className='mt-4' />
                <button className='mt-4 py-2 px-3 md:py-2 md:px-4 bg-purple-800 rounded-md text-white text-md hover:bg-purple-900 transition-all duration-300 disabled:opacity-75 flex gap-1 justify-center items-center' onClick={saveData} disabled={loading}>
                    Save
                    {
                        loading && (
                            <RiLoader5Line className='animate-spin'/>
                        )
                    }
                </button>
            </div>
        </>
    )
}
