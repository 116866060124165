import DashboardReport from './Components/DashboardReport'
import UserLayout from './UserLayout'
export default function App() {
  return (
    <UserLayout>
      <Dashboard />
    </UserLayout>
  )
}

export function Dashboard() {
  return (
    <div className='w-full mb-5'>
      <h2 className='text-2xl font-semibold mb-5'>Dashboard</h2>
      <DashboardReport />
    </div>
  )
}