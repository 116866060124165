import React, { useContext, useEffect, useState } from 'react'
import Menu from './Components/Menu'
import { Toaster } from 'react-hot-toast'
import UserAuth from './Context/UserAuth/UserContext'
import UserAuthProvider from './Context/UserAuth/UserAuthProvieder'
import { HiOutlinePlus } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { PiBellSimpleRinging } from "react-icons/pi";
import { PiBellSimpleRingingFill } from "react-icons/pi";
import { RxPinRight } from "react-icons/rx";
import NotifyList from './Components/NotifyList'
import { countNotificationApi, getNotificationApi } from './libs/apis'
import { RiLoader5Line } from "react-icons/ri";

export default function DashboardLayout({ children }) {
  return (
    <UserAuthProvider>
      <DashboardLayoutSet>
        {children}
      </DashboardLayoutSet>
    </UserAuthProvider>
  )
}

export function DashboardLayoutSet({ children }) {
  const { loginStatus, user } = useContext(UserAuth)
  const [notify, setNotify] = useState(false)
  const [notification, setNotification] = useState(null)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)

  // const Notify = (message) => {
  //   new Notification('SA Assistance', {
  //     body: message,
  //   });
  // }

  // const checkNotification = () => {
  //   if (Notification.permission === "granted") {
  //     // alert("we have permission");
  //     console.log("we have permission");
  //     return true
  //   } else if (Notification.permission !== "denied") {
  //     Notification.requestPermission().then(permission => {
  //       console.log(permission);
  //     });
  //     return false
  //   }
  // }

  const getNotifyData = async (page) => {
    setNotify(true)
    setLoading(true)
    setNotification(null)
    const res = await getNotificationApi({ userId: user?.id, role: user?.role, page })
    setNotification(res)
    setLoading(false)
  }

  const countNotifyData = async (user) => {

    const res = await countNotificationApi({ userId: user.id, role: user.role })
    if (res?.status) {
      setCount(res?.data)
      // if(res?.data > count){
      //   Notify(`(${res?.data}) new notification.`)
      // }
    } else {
      console.log(res?.message);
    }

  }

  useEffect(() => {
    // checkNotification()
    if (user.id) {
      countNotifyData(user)
    }
    setInterval(async () => {
      if (user.id) {
        countNotifyData(user)
      }
    }, 30000);
  }, [user])


  if (!loginStatus) {
    return (
      <div className="flex flex-wrap">
        <div className="relative bg-purple-900 text-white w-full lg:w-1/5 lg:h-screen p-5 flex lg:block justify-between shadow-lg lg:shadow-none">
          <div className='text-xl lg:text-2xl font-bold lg:text-center block'>
            <div className='min-h-[25px] w-[150px] rounded-lg bg-slate-200 animate-pulse'></div>
          </div>
          <div className='my-6 hidden lg:block'>
            <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-700 animate-pulse'></div>
            <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-800 animate-pulse'></div>
            <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-800 animate-pulse'></div>
            <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-800 animate-pulse'></div>
            <div className='min-h-[40px] mb-5 w-full rounded-lg bg-purple-800 animate-pulse'></div>
          </div>
        </div>
        <div className="flex-none w-full lg:w-4/5 p-5">
          <div className='min-h-[40px] mb-10 w-full rounded-lg bg-slate-200 animate-pulse'></div>
          <div className='min-h-[50vh] w-full rounded-lg bg-slate-200 animate-pulse'></div>
        </div>
      </div>
    )
  }

  let notStyle = 'w-full md:w-1/2 lg:w-1/3 xl:w-1/4 h-[100vh] fixed top-0 z-50 p-5 transition-all duration-150'
  return (
    <>
      <div className="flex flex-wrap w-full">
        <div className="fixed bg-purple-900 text-white w-full lg:w-1/5 lg:h-screen p-5 flex lg:block justify-between items-center shadow-lg lg:shadow-none z-50">
          <div className='text-xl lg:text-2xl font-bold lg:text-center block'>SA Assistance</div>
          {
            user?.role === 22 && (
              <div className=''>
                <Link to='/matter/create' className='flex gap-3 items-center justify-center w-full p-2 bg-white text-gray-900 font-medium rounded-lg lg:mt-4'>
                  <HiOutlinePlus className='text-xl' />
                  <span className='hidden lg:block'>Create Lead</span>
                </Link>
              </div>
            )
          }
          <Menu />
        </div>
        <div className="flex-none w-full lg:w-4/5 p-5 mt-24 lg:m-0 lg:ml-auto">
          {children}
        </div>
      </div>
      <div onClick={_ => {
        getNotifyData(1)
      }} className='fixed bottom-6 right-6 z-10 bg-green-400 hover:bg-green-500 p-3 text-2xl rounded-md cursor-pointer shadow-lg'>
        {
          count > 0 ? (
            <>
              <span className='absolute bg-purple-800 w-[20px] h-[20px] text-sm flex justify-center items-center text-white rounded-full -top-2 -left-2'>{count}</span>
              <PiBellSimpleRingingFill />
            </>
          ) : (
            <PiBellSimpleRinging />
          )
        }
      </div>
      <div className={notify ? notStyle + ' right-0 ' : notStyle + ' -right-full md:-right-1/2 lg:-right-1/3 '}>
        <div className='bg-white shadow-xl w-full h-full rounded-lg relative overflow-auto  p-3'>
          <div className='sticky top-0 left-0 flex gap-3 items-center z-10 bg-white/50 p-4 rounded-lg shadow-md border-2 border-slate-200 backdrop-blur-md'>
            <RxPinRight
              onClick={_ => {
                setNotify(false)
                setNotification(null)
                countNotifyData(user)
              }}
              className='text-2xl text-slate-700 cursor-pointer hover:text-slate-600' />
            <span className='text-lg'>Notifications</span>
          </div>
          <div className='w-full mt-8'>
            {
              loading && (
                <RiLoader5Line className='text-2xl animate-spin text-center mx-auto' />
              )
            }
            {
              notification?.status ? notification?.data?.map((n, i) => (
                <NotifyList key={i} data={n} user={user} />
              )) : (<p className='text-center'>{notification?.message}</p>)
            }

            {
              notification?.nextPage > 1 && (
                <div className='flex gap-2'>
                  <button className='p-3 rounded-md bg-slate-300 w-full cursor-pointer font-medium disabled:opacity-50 hover:bg-slate-400 transition-all' disabled={notification?.page > 1 ? false : true} onClick={_ => getNotifyData(notification?.prevPage)}>Newest</button>
                  <button className='p-3 rounded-md bg-slate-300 w-full cursor-pointer font-medium disabled:opacity-50 hover:bg-slate-400 transition-all' disabled={notification?.page === notification?.nextPage ? true : false} onClick={_ => getNotifyData(notification?.nextPage)}>Oldest</button>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <Toaster />
    </>
  )
}
