import React, { useContext, useEffect, useState } from 'react'
import DashboardLayout from './DashboardLayout';
import { userReportApi } from './libs/apis';
import AdminAuth from '../Context/AdminAuth/AdminContext';
import PieChart from './Components/PieChart';

export default function Dashboard() {

    // Change Page Title
    useEffect(()=>{
        document.title = 'Dashboard - SA Assistance';
    },[])

    return (
        <DashboardLayout>
            <DashboardPage/>
        </DashboardLayout>
    )
}

export function DashboardPage() {
    const [userReport, setUserRport] = useState(null)

    // get admin credentials
    const {admin} = useContext(AdminAuth)

    // Get User Report
    const getUserRerport = async (admin) => {
        let data = {adminUniqueId: admin?.id, adminRole: admin?.role}
        const res = await userReportApi(data)

        let dataArray = res?.map(u => {
            let a = null
            if(u?.role === 22){
                a = ["Introducers", u?.userCount]
            }
            if(u?.role === 33){
                a = ["Solicitors", u?.userCount]
            }
            if(u?.role === 39){
                a = ["Managers", u?.userCount]
            }
            return a
        })
        dataArray.unshift(["Users", "User count"])
        setUserRport(dataArray);
    }

    useEffect(()=>{
        getUserRerport(admin)
    },[admin])
    
    return (
        <div className='max-w-screen-sm rounded-lg border-2 border-slate-100 shadow-lg'>
            <PieChart data={userReport} />
        </div>
    )
}
