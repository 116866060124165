import React from 'react'

export default function Button({ type, onClick, text, disabled, className, size, block }) {
  let blockCss = 'w-full '
  let small =  'py-2 px-4 '
  let big = 'py-2 px-4 md:py-3 md:px-5 ';
  let tailCss = 'bg-purple-900 shadow-md text-white rounded-lg font-medium hover:bg-purple-950 transition-all duration-300 disabled:bg-purple-500 '
  
  if(size === 'big') {
    tailCss = tailCss + big
  }

  if(size === 'big' && block) {
    tailCss = tailCss + big + blockCss
  }

  if(size === 'small') {
    tailCss = tailCss + small
  }

  if(size === 'small' && block) {
    tailCss = tailCss + small + blockCss
  }

  if(className){
    tailCss = tailCss + className
  }
  
  return (
    <button 
        type={type} 
        className={tailCss}
        onClick={onClick}
        disabled={disabled ? true : false}
        >
            {text}
        </button>
  )
}
